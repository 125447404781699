.bd-page-title-bg_pricing {
    position: relative;
    background-color: var(--clr-bg-gray);
  }



.bd-page-title-bg_pricing:before {
    position: absolute;
    content:"";
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
  
    background: linear-gradient(to bottom, rgba(96, 8, 248, 0.2) 0%, rgba(96, 8, 248, 0.45) 100%);
    /* background: -moz-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: -webkit-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: linear-gradient(to right, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%); */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#1aef0454", endColorstr="#1a6008f8",GradientType=1 );
  }


.packages-content-text{
    color:var(--clr-body-text)  ;
    font-size:20px;
    font-weight:400;
    /* pointer-events: none; */
}

.question-text{
    font-size: 50px;
}

.theme-btn-black-middle{
  font-size: 12px;
display: inline-flex;
justify-content: center;
font-weight: 600;
background-color:var(--clr-bg-gray) ;
color:var(--clr-theme-1);
border: none;
outline: none;
box-shadow: none;
height: 50px;
line-height: 50px;
border-radius: 10px;
padding: 0px 38px;
cursor: pointer;
position: relative;
overflow: hidden;
text-transform: uppercase;
width:100%
}

.theme-btn-black-middle:hover{
  color:var(--clr-theme-1);
}

.theme-btn-black-middle::before{
  background-color:var(--clr-theme-1); ;
  content: "";
  height: 150px;
  left: -75px;
  position: absolute;
  top: -35px;
  transform: rotate(35deg);
  transition: all 1600ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 60px;
  opacity: 0;
}

.packages-tittle{
  font-size:50px;
  color:#4a026c
}

@media only screen and (min-width:350px) and (max-width:800px){
  .packages-tittle{
    font-size:34px;
  }
  .main-tittle-pricing{
    padding-top:125px
  }
}

@media (max-width: 767px){
  .question-text{
    font-size:36px;
  }
}

@media only screen and (min-width:350px) and (max-width:991px){
  .packages-content-text{
    font-size:18px;
  }
}

.seo-responsive-section1{
  width:25%
}
.seo-responsive-section2{
  width:25%
}
.seo-responsive-section3{
  width:25%
}
.seo-responsive-section4{
  width:25%
}

.pricing-seo{
  font-size:36px;
}

.website-main-tittle{
  font-size:46px
}

.first-Pricing_respons{
  width:31%
}
.second_Pricing_respons{
  width:69%
}
.first-seo_respons{
  width:33%
}
.second_seo_respons{
  width:67%
}
@media only screen and (min-width: 350px) and (max-width:991px){
  .first-Pricing_respons{
    width:100%
  }
  .second_Pricing_respons{
    width:100%
  }
}


.stickyPricing{
  position:sticky;
  top:106px;
  text-align: center;
  z-index: 99;
  background-color: rgb(255, 191, 30);
  
}

.bd-pricing-seo{
  padding: 25px 25px 25px 25px;
    padding-right: 25px;
    padding-left: 25px;
  transition: all 0.3s ease-out 0s;
  border-radius: 5px;
}

.pricing-table{
  display:flex
}
.package-price{
  width:20%
}
.package-content{
  width:60%
}
.buy-button{
  width:20%;
  /* padding-left:38% */
}
.main-pricing-content{
  color:black;
  font-size:20px;
  
}
.pricing-button{
  margin-top:80px;
  width:80%
}
.feature-tittle{
  font-weight: 600;
  font-size: 30px;
  height:112.33px;
}

.bd-pricing1{
  font-size: 11px;
  line-height: 1.65;
  list-style: none;
  margin-bottom: 20px;
  transition: all 0.3s ease-out 0s;
padding: 45px 25px 50px 25px;
transition: all 0.3s ease-out 0s;
border-radius: 5px;
}
.list-size-responsive{
  font-size: 16px;
}

.bd-pricing1.bd-pricing-active {
  background: var(--clr-theme-1);
}

.bd-pricing1 ul li {
  font-size: 16px;
  line-height: 1.65;
  list-style: none;
  margin-bottom: 20px;
  transition: all 0.3s ease-out 0s;
}

.section-responsive{
  font-size:20px;
  color:white;
  background-color:rgb(255, 191, 30) ;
  text-align: center;
}

.section1-responsive{
  font-size:20px;
  color:white;
  background-color:rgb(255, 191, 30) ;
  text-align: center;
}
.main-height{
  height:52.8px
}
.main2-height{
  height:79.2px
}


@media only screen and (min-width:350px) and (max-width:991px){
.pricing-table{
flex-direction: column
}
.package-price{
  width:100%
}
.package-content{
  width:100%
}
.buy-button{
  width:100%;
  padding-left:50px;
}
.pricing-button{
  width:200px;
  margin-top:30px;
}

.first-seo_respons{
  width:100%;
}
.second_seo_respons{
  width:100%
}
.website-main-tittle{
  font-size:34px
}
.feature-tittle{
  font-size: 14px;
}
.bd-pricing-seo{
  padding-left:0px;
  padding-right:0px
}
.bd-pricing1{
  padding-left:0px;
  padding-right:0px;
  padding-top:15px
}
.list-size-responsive{
  font-size:10px
}
.bd-pricing1 ul li{
  font-size: 11px;
}
.seo-responsive-section4{
  width:20%
}
.seo-responsive-section3{
  width:20%
}
.seo-responsive-section2{
  width:20%
}
.seo-responsive-section1{
  width:40%
}
.bd-pricing-subtitle{
  font-size:26px
}
.pricing-seo{
  font-size:13px
}
/* .bd-pricing-price span{
  font-size:0px;
} */
.feature-tittle{
  height:44px;
  padding-top:25px
}

.section-responsive{
  font-size:11px;
  line-height:1.65;
}
.section1-responsive{
  font-size:13px
}
.main-height{
  height:54.5px
}
.response-li{
  height:36.3px
}
.responsive-three-li{
  height:54.45px
}
.stickyPricing{
  top:87px;
}
}

@media only screen and (min-width:350px) and (max-width:1250px){
  .responsive_seo{
    display: none;
  }
  .responsive-side{
    padding-right:0px
  }
  .mobile-reponsive-seo{
    width:100%
  }
}

@media only screen and (min-width:1251px) and (max-width:1800px){
  .mobile-reponsive-seo{
    display: none;
  }
}

.size-pop{
  width:120%
}

@media only screen and (min-width:350px) and (max-width:700px){
  .size-pop{
    width:100%
  }
}

@media only screen and (min-width:350px) and (max-width:500px){
  .centering-detail{
    padding-left:0px
  }
}
