.bd-testimonial-four-text h6::before {
position: absolute;
content: "";
height: 1px;
background: var(--clr-body-text);
width: 80px;
top: 8px;
right: -86px;
}
.first-resp-section{
    width:50%;
}

.box-responsive{
    display:flex;
}

.test-first-section1{
    width:50%;
    text-align:center;
}

.test-first-section2{
    width:50%;
    text-align:center;
}

@media only screen and (min-width:350px) and (max-width:800px){
    .testimonial-responsive{
        padding-top: 220px;
    }
    .first-section-test{
        flex-direction: column
    }
    .resp-videos{
        width:100%;
        margin-bottom:0px;
    }
    .first-resp-section{
        width:100%
    }
    .box-responsive{
        flex-direction: column
    }
    .test-first-section1{
        width:100%;
        margin-right:0px 
    }
    .test-first-section2{
        width:100%;
        margin-right: 0px;
    }
    .responsive-main_testimonial{
        padding-left:10px;
        padding-right:10px;
    }
}

@media only screen and (min-width:350px) and (max-width:1100px){
    .resp-videos{
        width:100%;
        margin-bottom:0px;
    }
}

@media only screen and (min-width:801px) and (max-width:1000px){
    .test-first-section1{
        width:50%
    }
    .test-first-section2{
        width:115%;
    }

}