@media screen and (max-width: 768px) {
  .current-modal .modal-dialog {
    height: auto !important;
    /* overflow: scroll !important; */
  }
  .modal-2 .modal-content {
    top: 100% !important;
  }
  /* .current-modal .modal-content {
    overflow-y: scroll !important;
  } */
}
