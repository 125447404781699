/*  15. Pricing
/*----------------------------------------*/
.bd-pricing {
    background: var(--clr-bg-gray);
    padding: 45px 25px 50px 25px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    border-radius: 5px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 575px) {
    .bd-pricing {
      padding: 45px 25px 50px 25px;
    }
  }
  .bd-pricing-subtitle {
    /* font-size: 20px; */
    font-weight: 500;
    color: var(--clr-body-heading);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  .bd-pricing-price {
    font-size: 60px;
    line-height: 1.16;
    color: var(--clr-theme-1);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  .bd-pricing-price span {
    font-size: 24px;
    font-weight: 500;
    color: var(--clr-body-heading);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  .bd-pricing ul li {
    font-size: 16px;
    line-height: 1.65;
    list-style: none;
    margin-bottom: 20px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  .bd-pricing ul li i {
    font-size: 16px;
    color: var(--clr-theme-1);
    margin-right: 13px;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
  }
  .bd-pricing-btn .theme-btn,
  .bd-pricing-btn .theme-btn-black {
    width: 100%;
  }
  .bd-pricing.bd-pricing-active {
    background: var(--clr-theme-1);
  }
  .bd-pricing.bd-pricing-active .bd-pricing-subtitle,
  .bd-pricing.bd-pricing-active .bd-pricing-price,
  .bd-pricing.bd-pricing-active .bd-pricing-price span,
  .bd-pricing.bd-pricing-active ul li,
  .bd-pricing.bd-pricing-active ul li i {
    color: var(--clr-common-white);
  }
  .bd-pricing.bd-pricing-active .theme-btn {
    background: var(--clr-body-heading);
  }
  
  .bd-pricing-tabs {
    display: inline-flex;
    padding: 5px;
    background: var(--clr-bg-gray2);
    border-radius: 30px;
  }
  @media (max-width: 767px) {
    .bd-pricing-tabs {
      margin-bottom: 40px;
    }
  }
  .bd-pricing-tabs button {
    height: 50px;
    line-height: 50px;
    padding: 0px 30px;
    background: transparent;
    display: inline-block;
    border-radius: 30px;
    font-weight: 500;
    font-size: 16px;
    color: var(--clr-body-heading);
    border: none;
    outline: none;
    cursor: pointer;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .bd-pricing-tabs button {
      padding: 0px 22px;
    }
  }
  .bd-pricing-tabs button:last-child {
    margin-left: 6px;
  }
  .bd-pricing-tabs button.active {
    background: var(--clr-theme-1);
    color: var(--clr-common-white);
  }
  
  .bd-pricing-top {
    margin-top: -30px;
    position: relative;
  }
  
.open-text{
    color:var(--clr-body-text);
    font-weight: 400;
    font-size: 16px;
}

.opening-reponsive{
  width:30%
}

/* .bd-pricing:hover {
  background: var(--clr-theme-1);
  color:white;
}

.bd-pricing .bd-pricing-title-wraper .bd-pricing-subtitle ul li:hover{
  color:white;
} */

.bd-page-title-bg_career {
  position: relative;
  background-color: var(--clr-bg-gray);
}

.bd-page-title-bg_career:before {
  position: absolute;
  content:"";
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: linear-gradient(to bottom, rgba(96, 8, 248, 0.2) 0%, rgba(96, 8, 248, 0.45) 100%);

  /* background: -moz-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
  background: linear-gradient(to right, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%); */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#1aef0454", endColorstr="#1a6008f8",GradientType=1 );
}

@media only screen and (min-width:350px) and (max-width:991px){
  .main-heading{
    padding-top:125px;
  }
  .career-responsive{
padding-bottom: 30px;
  }
  .video-reponsive{
    width:100%
  }
  .opening-reponsive{
    width:100%
  }
}

.responsive-button{
  width:100%;
  padding-left: 45%;
}

@media only screen and (min-width:350px) and (max-width: 991px){
  .responsive-button{
    padding-left: 30%;
  }
}