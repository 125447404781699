.policy-text{
    font-size: 19px;
    line-height: 1.8;
}

.bd-page-title-bg_policy {
    position: relative;
    background-color: var(--clr-bg-gray);
  }


.bd-page-title-bg_policy:before {
    position: absolute;
    content:"";
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
  
    background: linear-gradient(to bottom, rgba(96, 8, 248, 0.2) 0%, rgba(96, 8, 248, 0.45) 100%);
  
    /* background: -moz-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: -webkit-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: linear-gradient(to right, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%); */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#1aef0454", endColorstr="#1a6008f8",GradientType=1 );
  }

  @media only screen and (min-width:350px) and (max-width:991px){
    .policy-responsive{
      margin-left: 15px;
      padding-top:70px;
    }
    .main-privacy-responsive{
      padding-top: 120px;
    }
  }