/*  Choose */
/*----------------------------------------*/
.bd-choose-info {
  display: flex;
}
.bd-choose-info-icon {
  margin-right: 30px;
}
.bd-choose-info-icon i {
  font-size: 50px;
  line-height: 1;
  display: inline-block;
  color: #c89fdb;
}
.bd-choose-info-text p {
  font-size: 16px;
  line-height: 1.75;
}
.bd-choose-info:hover i {
  animation-name: wobble-vertical;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 3;
}

.bd-choose-info-title {
  font-size: 20px;
  font-weight: 600;
}

.bd-choose-shape {
  position: relative;
}
.bd-choose-shape .bd-choose-shape {
  position: absolute;
}
.bd-choose-shape .bd-choose-shape-1 {
  top: 0px;
  right: -55px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bd-choose-shape .bd-choose-shape-1 {
    right: -100px;
  }
}

.bd-choose-img .bd-choose-shape-2 {
  position: absolute;
  top: 65px;
  left: 220px;
  z-index: -1;
  -webkit-animation: hero-dot-1 2s linear 0s infinite alternate;
  -moz-animation: hero-dot-1 2s linear 0s infinite alternate;
  -o-animation: hero-dot-1 2s linear 0s infinite alternate;
  animation: hero-dot-1 2s linear 0s infinite alternate;
}
.bd-choose-img .bd-choose-video-popup {
  position: absolute;
  top: 50%;
  left: calc(50% + 25px);
  transform: translate(-50%, -50%);
  border-radius: 50%;
  display: inline-block;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
   .bd-choose-video-popup {
    left: calc(50% + 0px);
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
   .bd-choose-video-popup {
    left: calc(50% + 5px);
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
   .bd-choose-video-popup {
    left: calc(50% + 55px);
  }
}
.bd-choose-img .bd-choose-video-popup a {
  height: 100px;
  width: 100px;
  text-align: center;
  display: inline-block;
  line-height: 60px;
  border: 20px solid var(--clr-common-white);
  background: var(--clr-theme-1);
  border-radius: 50%;
  color: var(--clr-common-white);
  font-size: 16px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .bd-choose-area.pb-105 {
    padding-bottom: 110px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-choose-img.text-end {
    display: inline-block;
    text-align: left !important;
  }
}

.play_btn::after, .play_btn::before {
  position: absolute;
  left: 0;
  top: 0;
  content: "";
  border-radius: 50%;
  width: 100%;
  height: 100%;
  border: 1px solid var(--clr-common-white);
  animation-name: popupBtn;
  animation-duration: 1.8s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.play_btn::before {
  animation-delay: 0.8s;
}

@keyframes popupBtn {
  0% {
    transform: scale(1);
    opacity: 0.6;
  }
  50% {
    transform: scale(1.6);
    opacity: 0.3;
  }
  100% {
    transform: scale(2.2);
    opacity: 0;
  }
}
.bd-choose-img-inner-1 {
  margin-right: 0px;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 767px) {
  .bd-choose-img-inner-1 {
    margin-right: 0px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-choose-img-inner-1 {
    margin-right: 0px;
  }
}

/* .bd-popup-video{
  
position: absolute;
bottom: -40%;
left: 70%;
transform: translate(-50%, -50%);
border-radius: 50%;
display: inline-block;
z-index: 99;
}

.play_popup-video{
height: 100px;
width: 100px;
text-align: center;
display: inline-block;
border: 20px solid var(--clr-common-white);
background: var(--clr-theme-1);
border-radius: 50%;
color: var(--clr-common-white);
font-size: 16px;
} */

  @media only screen and (min-width:999px) and (max-width: 1200px) {
    .bd-popup-video{
      bottom: 37%;
    }
  }

  @media only screen and (min-width:1201px) and (max-width: 1400px){
    .bd-popup-video{
      bottom: -250px;
      
    }
  }

  @media only screen and (min-width:1401px) and (max-width: 1600px){
    .bd-popup-video{
      bottom: 0px;
      left:74%
    }
  }

  .choose-main-responsive{
    padding-top: 120px;
  }
  .img_choose-responsive{
    padding-top:45px
  }
  .first-tittle-respon{
    margin-bottom: 15px;
  }
  
@media (min-width:1400px) {
  .img_choose-responsive{
    padding-top:0px
  }
}

  @media only screen and (min-width:350px) and (max-width: 991px){
.choose-main-responsive{
  padding-top: 80px;
}
.img_choose-responsive{
  padding-top:0px
}
.first-tittle-respon{
  margin-bottom:20px;
  margin-top:10px;
}
  }