.portfolio {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}

.portfolio-text {
  position: relative;
  top: 0px;
}

/* .bd-portfolio-text{
position: absolute;
bottom: 180px
} */

.breadcrumb {
  padding: 8px 15px;
  margin: 0 0 20px;
  list-style: none;
  background-color: #f5f5f5;
  -webkit-border-radius: 4px;
  -moz-border-radius: 4px;
  border-radius: 4px;
}

.breadcrumb > li {
  display: inline-block;
  display: inline;
  text-shadow: 0 1px 0 #ffffff;
  zoom: 1;
}

.breadcrumb > li > .divider {
  padding: 0 5px;
  color: #ccc;
}

.breadcrumb > .active {
  color: #999999;
}

.pt-220 {
  padding-top: 220px;
}

.bd-page-title-bg {
  position: relative;
}
.bd-page-title-bg:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  /* background-color: rgba(247, 195, 8, 0.67); */

  /* background: -moz-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: -webkit-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: linear-gradient(to right, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%); */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#1aef0454", endColorstr="#1a6008f8",GradientType=1 );
  background: linear-gradient(
    to bottom,
    rgba(96, 8, 248, 0.2) 0%,
    rgba(96, 8, 248, 0.45) 100%
  );
}

.bd-page-title .breadcrumb-title {
  font-size: 50px;
  font-weight: 700;
  line-height: 1.12;
}
/* @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .bd-page-title .breadcrumb-title {
      font-size: 76px;
    }
  } */
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bd-page-title .breadcrumb-title {
    font-size: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-page-title .breadcrumb-title {
    font-size: 50px;
  }
}
@media (max-width: 767px) {
  .bd-page-title .breadcrumb-title {
    font-size: 40px !important;
  }
  .bd-page-title .breadcrumb-title br {
    display: none;
  }
}
.bd-page-title .breadcrumb-trail .trail-items .trail-item {
  display: inline-block;
  list-style: none;
  margin: 0px 5px;
}
.bd-page-title .breadcrumb-trail .trail-items .trail-item a {
  font-size: 18px;
  color: var(--clr-theme-1);
}
.bd-page-title .breadcrumb-trail .trail-items .trail-item span {
  font-size: 18px;
  color: var(--clr-body-heading);
}
.bd-page-title .breadcrumb-trail .trail-items .trail-item i {
  color: var(--clr-body-heading);
}

.slick-dots {
  top: 250px;
  right: 225px;
  height: 50px;
}

.slick-dots li button:before {
  /* width:25px; */
  opacity: 0.2;
  font-size: 10px;
  line-height: 20px;
}

.slick-dots li.slick-active button::before {
  color: #5c0385;
  opacity: 1;
}

.bd-header-area.active1 {
  position: fixed;
  width: 100%;
  z-index: 999;
  background-color: white;
  animation: 800ms ease-in-out 0s normal none 1 running fadeInDown;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

@media (min-width: 1440px) and (max-width: 1366px) {
  .align-items-center {
    width: 100%;
  }
  .bd-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }

  .txt-cent {
    text-align: center;
  }
}

@media (min-width: 961px) and (max-width: 1025px) {
  .align-items-center {
    width: 100%;
  }
  .bd-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }
  .txt-cent {
    text-align: center;
    justify-content: center;
  }
}
@media (min-width: 761px) and (max-width: 960px) {
  .align-items-center {
    width: 100%;
  }
  .bd-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }
  .txt-cent {
    text-align: center;
    justify-content: center;
  }
}
@media (min-width: 642px) and (max-width: 760px) {
  .align-items-center {
    width: 100%;
  }
  .bd-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }
  .txt-cent {
    text-align: center;
    justify-content: center;
  }
}
@media (min-width: 481px) and (max-width: 641px) {
  .align-items-center {
    width: 100%;
  }
  .bd-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }
  .txt-cent {
    text-align: center;
    justify-content: center;
  }
}
@media (min-width: 301px) and (max-width: 480px) {
  .align-items-center {
    width: 100%;
  }
  .bd-spacing {
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 20px;
  }
  .txt-cent {
    text-align: center;
    justify-content: center;
  }
}
@media (min-width: 1441px) and (max-width: 1600px) {
  .align-items-center {
    width: 100%;
  }
  .bd-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }
  .txt-cent {
    text-align: center;
    justify-content: center;
  }
}
@media (min-width: 1601px) and (max-width: 1800px) {
  .align-items-center {
    width: 100%;
  }
  .bd-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }
  .txt-cent {
    text-align: center;
    justify-content: center;
  }
}
@media (min-width: 1801px) and (max-width: 2000px) {
  .align-items-center {
    width: 100%;
  }
  .bd-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }
  .txt-cent {
    text-align: center;
    justify-content: center;
  }
}

@media (min-width: 2001px) and (max-width: 2300px) {
  .align-items-center {
    width: 100%;
  }
  .bd-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }
  .txt-cent {
    text-align: center;
    justify-content: center;
  }
}

.txt {
  color: black;
}

.button1 {
  width: 100px;
  border: none;
  outline: none;
  box-shadow: none;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  border-radius: 6px;
  color: var(--clr-common-white);
  background-color: var(--clr-theme-1);
  height: 30px;
}

.button1:before {
  background-color: var(--clr-common-white);
  content: "";
  height: 150px;
  left: -75px;
  position: absolute;
  top: -35px;
  transform: rotate(35deg);
  transition: all 1600ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 60px;
  opacity: 0;
}

.button1:hover:before {
  left: 120%;
  transition: all 1300ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0.35;
}

.touch {
  position: absolute;
}

@media only screen and (min-width: 300px) and (max-width: 1024px) {
  .touch {
    width: 100%;
  }
}

@media (min-width: 1025px) {
  .touch {
    width: 100%;
  }
}

.touch-text {
  position: absolute;
  top: 111px;
  font-size: 40px;
  color: white;
  line-height: 55px;
  text-align: center;
  padding-left: 40px;
}

.touch-button {
  position: absolute;
  top: 114px;
  right: 160px;
}

.touch-btn1 {
  width: 150px;
  border: none;
  outline: none;
  box-shadow: none;
  font-weight: 600;
  font-size: 16px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  border-radius: 8px;
  color: black;
  background-color: white;
  height: 50px;
}

.touch-btn1:before {
  background-color: var(--clr-theme-1);
  content: "";
  height: 150px;
  left: -75px;
  position: absolute;
  top: -35px;
  transform: rotate(35deg);
  transition: all 1600ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 60px;
  opacity: 0;
}

.touch-btn1:hover:before {
  left: 120%;
  transition: all 1300ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0.55;
}

/* @media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .bd-main-menu1 ul li {
    margin-right: 25px;
  }
} */
.scroll-to-top-button {
  display: none;
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #c32948;
  color: #fff;
  border: none;
  border-radius: 50px;
  cursor: pointer;
  padding: 10px 17px;
  font-size: 16px;
  z-index: 999;
}
.callback-button {
  display: none;
  position: fixed;
  top: 50%;
  left: -75px;
  background-color: #c32948;
  color: #fff;
  border: none;
  /* cursor: pointer; */
  padding: 10px 17px;
  font-size: 16px;
  z-index: 999;
  transform: rotate(90deg);
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
}
.scroll-to-top-button.visible {
  display: block;
}
.callback-button.visible {
  display: block;
}

.message-form {
  position: fixed;
  bottom: 70px;
  right: 20px;
  color: #fff;
  border: none;
  border-radius: 60%;
  /* cursor: pointer; */
  font-size: 16px;
  z-index: 999;
  width: 25%;
  background: #f1f1f1;
  padding: 10px 0px 0px 10px;
  border-radius: 10px;
}

.callback-form {
  position: fixed;
  bottom: 70px;
  left: 45px;
  color: #fff;
  border: none;
  border-radius: 60%;
  /* cursor: pointer; */
  font-size: 16px;
  z-index: 999;
  width: 25%;
  background: #f1f1f1;
  padding: 10px 0px 0px 10px;
  border-radius: 10px;
}
.success-popup {
  position: fixed;
  bottom: 210px;
  left: 45px;
  color: #fff;
  border: none;
  border-radius: 60%;
  font-size: 16px;
  z-index: 999;
  width: 25%;
  background: #f1f1f1;
  padding: 10px 0px 0px 10px;
  border-radius: 10px;

}
.bg-success-green{
  background-color: #38ad48 !important;
  box-shadow: rgba(107, 107, 107, 0.35) 0px 5px 15px;
}
.form-tags {
  /* border: 1px solid #000 !important; */
  height: 40px !important;
}
.submit-chat {
  height: 40px !important;
  line-height: 40px !important;
}

.client-login {
  width: 80%;
  height: 40px;
  border: medium;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  font-weight: 400;
  font-size: 16px;
  padding-left: 20px;
  background-color: #c32948;
}
.client-login::after {
  content: "\25BC"; /* Unicode character for a downward-pointing triangle */
  position: absolute;
  top: 50%;
  right: 10px; /* Adjust the position as needed */
  transform: translateY(-50%);
  color: #ff0000;
}

.login-android {
  cursor: pointer;
}
option:checked,
option:hover {
  background-color: lime !important;
}
.bd-header-btn select option:hover {
  background: #79309b !important;
  color: #fff !important;
}
.login-android option:hover {
  background: #79309b !important;
  color: #fff !important;
}

.busfo a {
  font-size: 14.5px !important;
}
.busfo1 a {
  font-size: 14.5px !important;
  color: #000 !important;
}
.bd-main-menu1 ul li a {
  /* font-size: 13px; */
  font-size: 12.6px;
  font-weight: 600;
  color: black;
  display: block;
  padding: 43px 0px;
  transition: all linear 0.3s;
  position: relative;
}

.bd-main-menu1 ul li {
  list-style: none;
  display: inline-block;
  margin-right: 15px;
  position: relative;
  /*submenu start*/
  /*submenu end*/
}

.bd-main-menu1 ul li a:hover,
.bd-main-menu1 ul li a.active {
  color: #c32948;
}
.bd-main-menu1 ul li.menu-item-has-children > a:after {
  margin-left: 0px;
  font-family: "Font Awesome 5 Pro";
  font-size: 13px;
  color: var(--clr-common-white);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.bd-main-menu1 ul li.menu-item-has-children > a:hover:after,
.bd-main-menu1 ul li.menu-item-has-children > a.active:after {
  color: var(--clr-theme-1);
}

.section-number {
  font-size: 20px;
}

.first_resp_audit {
  width: 55%;
}
.second_resp_audit {
  width: 45%;
}

.sixth-title {
  font-size: 65px;
}

.top-border-line {
  border-top: 2px solid #dfdcdc;
  padding-bottom: 30px;
}

@media only screen and (min-width: 1200px) and (max-width: 1326px) {
  .bd-main-menu1 ul li a {
    font-size: 12px;
  }

  .bd-main-menu1 ul li {
    margin-right: 10px;
  }
}

.animate-hover:hover i {
  animation-name: wobble-vertical;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 3;
}

.title_resp {
  font-size: 46px;
}
.sixth-title {
  font-size: 46px;
}

@media only screen and (min-width: 350px) and (max-width: 991px) {
  .second_resp_audit {
    flex-direction: column;
  }
  .first_resp_audit {
    width: 100%;
  }
  .second_resp_audit {
    width: 100%;
  }
  .cell_resp {
    margin-right: 0px;
  }
  .cell_resp2 {
    margin-left: 0px;
  }
  .block_two {
    margin-left: 0px;
  }

  .para_one {
    margin-left: 0px;
  }
  .title_resp {
    margin-left: 0px;
  }
  .callback-form{
    width: 90%;
    left: 29px;
  }
  .message-form{
    width: 90%;
  }
}

@media (max-width: 575px) {
  .sixth-title {
    font-size: 40px;
  }
}

@media only screen and (min-width: 350px) and (max-width: 450px) {
  .bd-slider-six-img .bd-slider-six-img-shape1 {
    right: -40px;
  }

  .bd-single-slider {
    padding-bottom: 50px;
  }
}

.para_resp_one {
  width: 95%;
}

@media only screen and (min-width: 350px) and (max-width: 1100px) {
  .second_flex {
    margin-left: 0px;
  }
  .cell_padd_resp {
    padding-left: 0px;
  }
  .bd-slider-six {
    text-align: left;
  }
  .para_resp_one {
    width: 100%;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1030px) {
  .responsive_audit {
    position: absolute;
    top: -550px;
    left: calc(62% + 80px);
    z-index: 3;
  }
}

@media only screen and (min-width: 576px) and (max-width: 750px) {
  .three_section_resp {
    flex-direction: column;
  }
}

@media only screen and (min-width: 350px) and (max-width: 991px) {
  .bd-choose-img {
    margin-top: 0px;
  }
}

@media (max-width: 767px) {
  .title_resp {
    font-size: 36px;
  }
  .sixth-title {
    font-size: 36px;
  }
}

.bordertop-img1 {
  border-top-left-radius: 90px;
}

.bordertop-img2 {
  border-top-right-radius: 90px;
}
.bordertop-img3 {
  border-bottom-left-radius: 90px;
}

@media only screen and (min-width: 350px) and (max-width: 500px) {
  .bordertop-img1 {
    border-top-left-radius: 60px;
  }

  .bordertop-img2 {
    border-top-right-radius: 60px;
  }
  .bordertop-img3 {
    border-bottom-left-radius: 60px;
  }
}
