.first_pop_video{
position: relative;
bottom: 300px;
left: calc(70% + 50px);
transform: translate(-50%, -50%);
border-radius: 50%;
display: inline-block;
z-index: 3;
}

.responsive_digital{
    height: 100px;
width: 100px;
text-align: center;
display: inline-block;
line-height: 60px;
border: 20px solid var(--clr-common-white);
background: var(--clr-theme-1);
border-radius: 50%;
color: var(--clr-common-white);
font-size: 16px;
}

.play_bt_vid{
    color:white;
    text-align: center;
    margin-bottom:4px;
    font-size: 20px;
}

.play_transition::before{
    position: absolute;
left: 0;
top: 0;
content: "";
border-radius: 50%;
width: 100%;
height: 100%;
border: 1px solid var(--clr-common-white);
animation-name: popupBtn;
animation-duration: 1.8s;
animation-timing-function: linear;
animation-iteration-count: infinite;
}

@media only screen and (min-width: 1400px) and (max-width: 1569px){
    .first_pop_video{
        bottom: 310px;
        left:calc(70% + 30px);
    }
}
@media only screen and (min-width: 1570px) and (max-width: 1700px) {
    .first_pop_video{
        bottom: 310px;
        left:calc(70% + 0px);
    }
}

@media only screen and (min-width: 1701px) and (max-width: 1850px) {
    .first_pop_video{
        bottom: 310px;
        left:calc(68% + 0px);
    }
}

@media only screen and (min-width: 350px) and (max-width: 500px) {
    .media-respon-digital {
        padding-top: 100px;
    }
    .bd-choose-img .bd-choose-shape-2{
        position: absolute;
        left:130px;
        width:100px;
        top:150px
    }
}

@media only screen and (min-width: 350px) and (max-width: 400px) {

.play_transition{
    font-size:14px;
    line-height:20px;
    margin-bottom: 18px;
    margin-left:2px;
}
.responsive_digital{
    position:absolute;
    top:728px;
    left:calc(40% + 30px);
    width:65px;
    height:65px;
    border: 15px solid var(--clr-common-white);
}
}

@media only screen and (min-width: 401px) and (max-width: 450px) {
    .play_transition{
        font-size:14px;
        line-height:20px;
        margin-bottom: 18px;
        margin-left:2px;
    }
    .responsive_digital{
        position:absolute;
        top:735px;
        left:calc(40% + 30px);
        width:65px;
        height:65px;
        border: 15px solid var(--clr-common-white);
    }
}

@media only screen and (min-width: 451px) and (max-width: 500px) {
    .play_transition{
        font-size:14px;
        line-height:20px;
        margin-bottom: 16px;
        margin-left:2px;
    }
    .responsive_digital{
        position:absolute;
        top:760px;
        left:calc(40% + 30px);
        width:72px;
        height:72px;
        border: 15px solid var(--clr-common-white);
    }
}

@media only screen and (min-width: 501px) and (max-width: 550px) {
    .play_transition{
        font-size:14px;
        line-height:20px;
        margin-bottom: 16px;
        margin-left:2px;
    }
    .responsive_digital{
        position:absolute;
        top:700px;
        left:calc(40% + 40px);
        width:80px;
        height:80px;

    }
    .bd-choose-img .bd-choose-shape-2{
        position: absolute;
        left:180px;
        width:120px;
    }
}

@media only screen and (min-width: 551px) and (max-width: 600px) {
    .play_transition{
        font-size:14px;
        line-height:20px;
        margin-bottom: 16px;
        margin-left:2px;
    }
    .responsive_digital{
        position:absolute;
        top:730px;
        left:calc(40% + 40px);
        width:80px;
        height:80px;

    }
    .bd-choose-img .bd-choose-shape-2{
        position: absolute;
        left:180px;
        width:120px;
    }
}

@media only screen and (min-width: 601px) and (max-width: 650px) {
    .responsive_digital{
        position:absolute;
        top:745px;
        left:calc(42% + 40px);
    }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
    .resp-img{
        width:100%
    }
}

@media only screen and (min-width: 651px) and (max-width: 767px) {
    .responsive_digital{
        position:absolute;
        top:775px;
        left:calc(43% + 40px);
    }
}

@media only screen and (min-width: 768px) and (max-width: 830px) {
    .responsive_digital{
        position:absolute;
        top:740px;
        left:calc(35% + 20px);
    }
}
@media only screen and (min-width: 830px) and (max-width: 920px) {
    .responsive_digital{
        position:absolute;
        top:740px;
        left:calc(34% + 0px);
    }
}
@media only screen and (min-width: 921px) and (max-width: 960px) {
    .responsive_digital{
        position:absolute;
        top:740px;
        left:calc(32% + 0px);
    }
}
@media only screen and (min-width: 961px) and (max-width: 991px) {
    .responsive_digital {
        position:absolute;
        top:740px;
        left:calc(31% + 0px);
    }
}