.bd-page-title-bg3 {
    position: relative;
    background-color: var(--clr-bg-gray);
  }
  .bd-page-title-bg3:before {
    position: absolute;
    content:"";
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;

    background: linear-gradient(to bottom, rgba(96, 8, 248, 0.2) 0%, rgba(96, 8, 248, 0.45) 100%);
    /* background: -moz-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: -webkit-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: linear-gradient(to right, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%); */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#1aef0454", endColorstr="#1a6008f8",GradientType=1 );
  }

    
    .touch_pop_vid{
        height: 100px;
    width: 100px;
    text-align: center;
    display: inline-block;
    line-height: 60px;
    border: 20px solid var(--clr-common-white);
    background: var(--clr-theme-1);
    border-radius: 50%;
    color: var(--clr-common-white);
    font-size: 16px;
    }
    
    .play_bt_vid{
        color:white;
        text-align: center;
        margin-bottom:4px;
        font-size: 20px;
    }
    
    .play_transition::before{
        position: absolute;
    left: 0;
    top: 0;
    content: "";
    border-radius: 50%;
    width: 100%;
    height: 100%;
    border: 1px solid var(--clr-common-white);
    animation-name: popupBtn;
    animation-duration: 1.8s;
    animation-timing-function: linear;
    animation-iteration-count: infinite;
    }
    
    .first_pop_video_software{
      position: relative;
      bottom:430px;
      left:270px;
      z-index: 3;
    }
  
    @media only screen and (min-width: 1400px) and (max-width: 1450px){
      .first_pop_video_software{
          left:calc(20% + 10px);
          bottom:470px;
      }
  }
  @media only screen and (min-width: 1451px) and (max-width: 1500px){
    .first_pop_video_software{
        left:calc(20% + 30px);

    }
  }
  @media only screen and (min-width: 1501px) and (max-width: 1550px){
    .first_pop_video_software{
        left:calc(20% + 50px);
    }
  }
  @media only screen and (min-width: 1551px) and (max-width: 1659px){
    .first_pop_video_software{
        left:calc(25% + 0px);

    }
  }
  
  @media only screen and (min-width: 1660px) and (max-width: 1730px){
    .first_pop_video_software{
        left:calc(25% + 20px);
    }
  }
  @media only screen and (min-width: 1731px) and (max-width: 1800px){
    .first_pop_video_software{
        left:calc(25% + 40px);
    }
  }

  .software-first-section1{
    width:45%;
  }
  .software-first-section2{
    width:55%;
  }
  .software-responsive-tittle{
    font-size: 46px;
  }
  .second-responsive-software1{
    width:55%
  }
  .second-responsive-software2{
    width:45%
  }
  .sixth-section-soft{
    font-size:46px;
  }
  .sixth-soft-section1{
    width:50%
  }
  .seventh-soft-tittle{
    font-size:46px;
  }
  .seventh-soft-section1{
    width:35%;
  }
  .seventh-soft-section2{
    width:65%;
  }
  .kick-section-tittle{
    font-size:46px
  }

  .fiveth-section-soft{
    font-size:46px
  }
  @media only screen and (min-width:350px) and (max-width:991px){
    .software-first-section1{
      width:100%
    }
    .software-first-section2{
      width:100%
    }
    .first_section-title{
      font-size: 36px;
    }
    .responsive-second-part{
      flex-direction: column-reverse;
    }
    .software-responsive-tittle{
      font-size:36px;
      margin-left: 0px;
    }
    .second-responsive-software1{
      width:100%;
      
    }
    .second-responsive-software2{
      width:100%
    }
    .para-software{
      margin-left: 0px  ;
    }
    .main-section-software{
      padding-top: 100px
    }
    .extra-para{
      font-size:18px
    }
    .soft-responsive{
      padding-left: 10px;
    }
    .sixth-section-soft{
      font-size:36px;
    }
    .sixth-main-responsive{
      flex-direction: column
    }
    .sixth-soft-section1{
      width:100%
    }
    .seventh-main{
      flex-direction: column-reverse;
    }
    .seventh-soft-tittle{
      font-size:36px;
    }
    .seventh-soft-section1{
      width:100%
    }
    .seventh-soft-section2{
      width:100%;
      padding-left: 20px
    }
  }

  .soft-para{
    font-size:18px;
    line-height: 1.75
  }
  .point-software{
    font-size:20px;
    font-weight: 550;
  }