.bd-page-title-bg1 {
    position: relative;
    background-color: var(--clr-bg-gray);
  }
  .bd-page-title-bg1:before {
    position: absolute;
    content:"";
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: linear-gradient(to bottom, rgba(96, 8, 248, 0.2) 0%, rgba(96, 8, 248, 0.45) 100%);
  
    /* background: -moz-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: -webkit-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: linear-gradient(to right, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%); */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#1aef0454", endColorstr="#1a6008f8",GradientType=1 );
  }

.animate-hover:hover i{
  animation-name: wobble-vertical;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 3;
  }

  .first_pop_video_bespoke{
    position: relative;
    bottom:360px;
    left:270px;
    z-index: 3;
  }

  @media only screen and (min-width: 1400px) and (max-width: 1450px){
    .first_pop_video_bespoke{
        left:calc(20% + 10px);
        bottom:390px;
    }
}
@media only screen and (min-width: 1451px) and (max-width: 1500px){
  .first_pop_video_bespoke{
      left:calc(20% + 30px);
      bottom:400px;
  }
}
@media only screen and (min-width: 1501px) and (max-width: 1550px){
  .first_pop_video_bespoke{
      left:calc(20% + 50px);
      bottom:400px;
  }
}
@media only screen and (min-width: 1551px) and (max-width: 1659px){
  .first_pop_video_bespoke{
      left:calc(25% + 0px);
      bottom:400px;
  }
}

@media only screen and (min-width: 1660px) and (max-width: 1730px){
  .first_pop_video_bespoke{
    bottom:400px;
      left:calc(25% + 20px);
  }
}
@media only screen and (min-width: 1731px) and (max-width: 1800px){
  .first_pop_video_bespoke{
    bottom:400px;
      left:calc(25% + 40px);
  }
}

.first_resp_bespoke{
  width:45%
}

.second_resp_bespoke{
  width:55%
}

.first_section_responsive{
  width:70%
}
.second_section_responsive{
  width:30%
}
.four_point_section1{
  width:45%

}
.four_point_section2{
  width:55%
}
.section_title2{
  font-size:46px;
}

.fifth_section_respon{
  width:65%
}
.fifth_section_respon2{
  width:35%
}
.bespoke_section2-tittle{
  font-size:46px;
}

.bespoke-lastSection_tittle{
  font-size:46px;
}

@media only screen and (min-width:350px) and (max-width:991px) {
  .first_resp_bespoke{
    width:100%
  }
  .second_resp_bespoke{
    width:100%
  }
  .bespoke_responsive{
    flex-direction: column;
  }
  .first_section_responsive{
    width:100%
  }
  .second_section_responsive{
    width:100%
  }
  /* .four_resp_section{
    flex-direction: column;
  } */
  .four_point_section1{
    width:100%;
    padding-top: 50px;
  }
  .four_point_section2{
    width: 100%;
  }
  .fifth_section_respon{
    width:100%;
  }
  .fifth_section_respon2{
    width:100%
  }

}

@media (max-width: 767px){
  .section_title2{
    font-size:36px
  }
  .bespoke_section2-tittle{
    font-size:36px;
  }
  .bespoke-lastSection_tittle{
    font-size:36px;
  }
}



