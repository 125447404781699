.bd-page-title-bg4 {
    position: relative;
    background-color: var(--clr-bg-gray);
  }
  .bd-page-title-bg4:before {
    position: absolute;
    content:"";
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;

    background: linear-gradient(to bottom, rgba(96, 8, 248, 0.2) 0%, rgba(96, 8, 248, 0.45) 100%);
    /* background: -moz-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: -webkit-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: linear-gradient(to right, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%); */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#1aef0454", endColorstr="#1a6008f8",GradientType=1 );
  }

  .first_pop_mobile{
    position: relative;
    bottom: 345px;
    left: calc(70% + 50px);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    display: inline-block;
    z-index: 3;
    }

    @media only screen and (min-width: 1400px) and (max-width: 1569px){
      .first_pop_mobile{
        bottom: 370px;
        left:calc(70% + 30px);
      }
  }
  @media only screen and (min-width: 1570px) and (max-width: 1700px) {
      .first_pop_mobile{
          left:calc(70% + 0px);
          bottom: 370px;
      }
  }
  
  @media only screen and (min-width: 1701px) and (max-width: 1850px) {
      .first_pop_mobile{
          left:calc(68% + 0px);
          bottom: 370px;
      }
  }


  .second-mobile-section1{
    width:55%;
  }
  .second-mobile-section2{
    width:45%
  }
  .mobile-subpoint-section{
    width:50%;
  }
  .mobile-fifth-sec1{
    width:55%;
  }
  .mobile-fifth-sec2{
    width:45%;
  }
  .four-point-tittle{
    font-size:46px;
  }
  .mobile-main-title{
    font-size:50px;
    color:#4a026c
  }
  .third-mobile-tittle{
    font-size:46px;
    text-align:start
  }
  .fourth-sec-tittle{
    font-size:46px;
  }

  @media (max-width: 767px){
    .first-mobile-tittle{
        font-size:36px
    }
    .four-point-tittle{
        font-size:36px
    }
    .third-mobile-tittle{
      font-size:36px
    }
    .fourth-sec-tittle{
      font-size:36px;
    }
    
  }

  @media only screen and (min-width:350px) and (max-width:991px){
    .second-mobile-section1{
        width:100%;    
    }
    .second-mobile-section2{
        width:100%;
    }
    .four-section{
        margin-right:40px;
    }
    .four-section2{
        margin-right: 30px;
    }
    .second-mobile-responsive{
        flex-direction: column-reverse;
    }
    
    .third-mobile-section1{
        flex-direction: column;
    }
    .mobile-subpoint-section{
        width:100%
    }
    .six-feature-mobile{
        padding-left: 0px;
    }
    .service-mobile{
        padding-left: 10px;
        padding-right: 0px;

    }
    .fifth-responsive-sec{
        flex-direction: column;
    }
    .mobile-fifth-sec1{
        width:100%;
    }
    .mobile-fifth-sec2{
        width:100%
    }
    .mobile-para1{
        margin-left:0px
    }
    .four-point-tittle{
        margin-left:0px
    }
    .mobile-main-title{
      font-size:44px
    }

  }
