.bd-cookie-section{
	display:flex;
}
.bd-cookies-section1{
    width:80%;
}

.bd-setting-cookie{
    background: var(--clr-theme-1) none repeat scroll 0% 0%;
    border: 0px none;
    border-radius: 10px;
    box-shadow: none;
    color: white;
    cursor: pointer;
    flex: 0 0 auto;
    padding: 10px;
    font-weight: 600;
    width: 150px;
}

.bd-fade-cookie {
    animation-duration: 3s;
    animation-name: slidein;
  }

  @keyframes slidein {
    from {
      margin-left: 100%;
      width: 300%;
    }
    to {
      margin-left: 0%;
      width: 100%;
    }
  }

@media only screen and (min-width: 1400px) and (max-width: 1600px){
    .bd-cookies-section1{
        width:82%;
    }
}
.bd-tab{
    display:flex;
    height:420px;
}
.react-tabs{
    display:flex;
}
.bd-modal-cookie-1{
    width:35%;
    margin-right:10px
}
.bd-modal-cookie-2{
    width:65%;
    margin-top:30px;
    margin-left:20px;
}
.bd-cookie-text{
    font-size:14px;
    line-height:1.8;
}
.bd-save-cookie-sett{
    background: rgb(255, 191, 30) none repeat scroll 0% 0%;
border: 0px none;
border-radius: 8px;
box-shadow: none;
color: black;
cursor: pointer;
flex: 0 0 auto;
padding: 10px;
margin: 0px 15px 15px;
font-weight: 600;
width: 150px;
}


.w100{
    width:100%;
    text-align:center
}

.bd-btns-cookie{
    width:30%
}
.bd-btns-cookie2{
    width:70%;
    text-align:right
}

/* tabs CSS */

.react-tabs {
    -webkit-tap-highlight-color: transparent;
  }

  .react-tabs__tab {
    display: inline-block;
    border: 1px solid transparent;
    border-bottom: none;
    bottom: -1px;
    position: relative;
    list-style: none;
    padding: 15px 12px;
    cursor: pointer;
    color:black;
    width:100%;
    background-color: #F4F4F4;
    border-top:1px solid rgba(0,0,0,0.5);

  }


  .react-tabs__tab--disabled {
    color: GrayText;
    cursor: default;
  }

  .react-tabs__tab:focus {
    outline: none;
  }



  .react-tabs__tab--selected {
    background-color: white;
    border-top: 1px solid black;
    /* border-bottom: 1px solid rgba(0,0,0); */
    border-left:8px solid var(--clr-theme-1)
  }

  .modal-main{
    width: 100vw;
    height: 100vh;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    z-index: 1060;
    overflow-x: hidden;
    overflow-y: auto;
    outline: 0;
  }