.bd-page-title-bg6 {
    position: relative;
    background-color: var(--clr-bg-gray);
  }
  .bd-page-title-bg6:before {
    position: absolute;
    content:"";
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;

    background: linear-gradient(to bottom, rgba(96, 8, 248, 0.2) 0%, rgba(96, 8, 248, 0.45) 100%);
    /* background: -moz-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: -webkit-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: linear-gradient(to right, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%); */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#1aef0454", endColorstr="#1a6008f8",GradientType=1 );
  }

  .second_sec-tittle{
    font-size: 46px;
  }

  .third-sec1-responsive{
    width:35%;
  }

  .third-sec2-responsive{
    width:65%;
  }

  .first_pop_video_dedicated{
    position: relative;
    bottom:360px;
    left:270px;
    z-index: 3;
  }

  .first_dedicate{
    width:45%
  }

  .second_dedicated{
    width:55%
  }

  .deicated-second_sec{
    width:45%;
  }

  .deicated-first_sec{
    width:55%;
  }

  .fourth_responsive_section1{
    width:55%;
  }
  .fourth_responsive_section2{
    width:45%;
  }
  .dedicate-last-tittle{
    font-size:46px
  }

  .fourth_sec_tittle-dedicat{
    font-size: 46px;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1450px){
    .first_pop_video_dedicated{
        left:calc(20% + 10px);
    }
}
@media only screen and (min-width: 1451px) and (max-width: 1500px){
  .first_pop_video_dedicated{
      left:calc(20% + 30px);

  }
}
@media only screen and (min-width: 1501px) and (max-width: 1550px){
  .first_pop_video_dedicated{
      left:calc(20% + 50px);
  }
}
@media only screen and (min-width: 1551px) and (max-width: 1659px){
  .first_pop_video_dedicated{
      left:calc(25% + 0px);

  }
}

@media only screen and (min-width: 1660px) and (max-width: 1730px){
  .first_pop_video_dedicated{
      left:calc(25% + 20px);
  }
}
@media only screen and (min-width: 1731px) and (max-width: 1800px){
  .first_pop_video_dedicated{
      left:calc(25% + 40px);
  }
}
@media (max-width: 767px){
  .second_sec-tittle{
    font-size:38px;
    margin-left: 0px;
  }
  .dedicat-para1{
margin-left: 0px;
  }
  .fourth_sec_tittle-dedicat{
    font-size: 38px;
  }
  .dedicate-first-tittle{
    font-size:38px;
  }
  .dedicate-last-tittle{
    font-size:38px;
  }
}

@media only screen and (min-width:350px) and  (max-width: 991px){
  .first_dedicate{
    width:100%
  }

  .second_dedicated{
    width:100%
  }

.dedicated_first_responsive{
  flex-direction: column-reverse;
}
  .deicated-first_sec{
    width:100%;
  }
  .deicated-second_sec{
    width:100%;
  }

  .third-sec2-responsive{
    width:100%;
  }
  .third-sec1-responsive{
    width:100%
  }
  .second-dedicated-resp{
    flex-direction: column;
  }

  .fourth_responsive_section1{
    width:100%
  }
  .fourth_responsive_section2{
    width:100%
  }
  .third-point-col{
    margin-right:30px
  }
  .first-point-col{
    margin-right: 40px;
  }

}