/* Brand
/*----------------------------------------*/
/* .bd-brand-single img {
    opacity: 0.2;
  } */

  .slider{
    text-align:center;
    display: block;
    padding: 0px 60px 0px 60px
  }


  
  .bd-brand-single img:hover {
    opacity: 1;
  }
  
  .bd-brand-single {
    text-align: center;
    padding:0px 20px 0px 20px;
  }
  
  @media (max-width: 767px) {
    .bd-brand-item.text-center {
      text-align: start !important;
    }
  }
  
  .bd-brand-area-four-title {
    position: relative;
  }
  .bd-brand-area-four-title h4 {
    font-size: 24px;
    margin-bottom: 0px;
    line-height: 1;
    font-weight: 600;
    display: inline-block;
    padding: 0px 20px;
    background: var(--clr-common-white);
  }
  .bd-brand-area-four-title::before {
    position: absolute;
    content: "";
    height: 7px;
    width: 100%;
    top: 10px;
    left: 0px;
    border-top: 1px solid var(--clr-border-4);
    border-bottom: 1px solid var(--clr-border-4);
    z-index: -1;
  }
  
  @media (max-width: 767px) {
    .bd-brand-area-four.pb-150 {
      padding-bottom: 145px;
    }
  }

  
 