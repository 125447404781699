.bd-contact-field input, .bd-contact-field textarea {
    height: 60px;
    width: 90%;
    border-radius: 10px;
    border: none;
    background: var(--clr-common-white);
    font-size: 16px;
    font-weight: 400;
    padding: 0px 20px;
    outline: none;
    color: var(--clr-body-text);
}

.bd-contact-field input::placeholder, .bd-contact-field textarea::placeholder {
    font-size: 16px;
    opacity: 1;
    color: var(--clr-body-text);
}

.bd-contact-field input::placeholder, .bd-contact-field textarea::placeholder {
    font-size: 16px;
    opacity: 1;
    color: var(--clr-body-text);
}

.bd-contact-form-wrapper .bd-contact-field input, .bd-contact-form-wrapper .bd-contact-field textarea {
    background: var(--clr-bg-gray);
}

.post {
    height: 90px;
    width: 80px;
    border-radius: 10px;
    background-size: cover;
    background-position: left;
    margin-right: 15px;
}

.icons{
    position: absolute;
    /* content: "\f061"; */
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    font-family: "Font Awesome 5 Pro";
    font-size: 14px;
    color: var(--clr-theme-1);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.icons:hover {
    color: var(--clr-theme-1);
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
}

.icons:hover:before {
    left: 7px;
}

.sub-content{
    font-size: 19px;
    font-weight: 600;
    line-height: 30px;


}

.list-content{
    font-size:20px;
    color: var(--clr-body-text);
    font-weight: 200;
    margin-bottom: 8px;
}

.sublist{
    color: var(--clr-body-text);
    font-size: 16px;
    font-weight:400;

}

li ::before{
    color:var(--clr-theme-1);
}

.MainList{
    color:black ;
    font-size: 25px;
    font-weight:400;
    padding-left: 15px;
}

.ans-content{
    color: var(--clr-body-text);
    font-size: 20px;
    font-weight:500
}

/* @media (min-width:350px) and (max-width:500px) {
    .blogs{
        display: none;  
    }
} */

@media (min-width:350px) and (max-width:500px) {
    .bd-page-title-area::before{
        height:130%
    }
}

.bd-page-title-bg_blog {
    position: relative;
    background-color: var(--clr-bg-gray);
  }

  .bd-page-title-bg_blog:before {
    position: absolute;
    content:"";
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: linear-gradient(to bottom, rgba(96, 8, 248, 0.2) 0%, rgba(96, 8, 248, 0.45) 100%);
  
  
    /* background: -moz-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: -webkit-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: linear-gradient(to right, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%); */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#1aef0454", endColorstr="#1a6008f8",GradientType=1 );
  }

.Blog-responsive-Section1{
    width:73%;
}
.rescentPost-responsive{
    width:27%;
}
.blogdetail-main-tittle{
    font-size:50px;
    color:#4a026c;
    line-height:1.12;
}

@media only screen and (min-width:350px) and (max-width:1100px){
    .Blog-responsive-Section1{
        width:100%
    }
    .rescentPost-responsive{
        width:100%;
    }
    .blogdetail-main-tittle{
        font-size:34px;
    }
    .blogdetail_responsive-ban{
        padding-top: 130px;
    }
}

@media only screen and (min-width:1101px) and (max-width:1200px){
    .Blog-responsive-Section1{
        width:70%;
    }
    .rescentPost-responsive{
        width:30%;
    }
}

