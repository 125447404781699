/* Service */
/*----------------------------------------*/
.bd-service {
  border-radius: 5px;
  background: var(--clr-common-white);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  padding: 38px 35px 30px 35px;
  min-height: calc(100% - 30px);
}
.bd-service:hover {
  box-shadow: 0px 20px 30px rgba(55, 7, 23, 0.07);
}
.bd-service:hover i {
  animation-name: wobble-vertical;
  animation-duration: 1s;
  animation-timing-function: ease-in-out;
  animation-iteration-count: 3;
}
.bd-service p {
  font-size: 16px;
  line-height: 1.75;
}

.bd-service-title {
  font-size: 22px;
  font-weight: 600;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.bd-service-title a:hover {
  color: var(--clr-theme-1);
}

.bd-service-icon i {
  font-size: 50px;
  line-height: 1;
  display: inline-block;
  color: var(--clr-theme-1);
}

.bd-service-four {
  background: var(--clr-bg-gray);
}

.bd-service-details-img img {
  border-radius: 10px;
}

.bd-service-details h4 {
  font-size: 28px;
  font-weight: 600;
  color: var(--clr-body-heading);
}
.bd-service-details h5 {
  font-size: 18px;
  color: var(--clr-body-heading);
}
.bd-service-details p {
  font-size: 16px;
  line-height: 26px;
  color: var(--clr-body-text);
}
.bd-service-details-feature ul li {
  display: flex;
  list-style: none;
  line-height: 1;
  font-size: 16px;
  color: var(--clr-body-heading);
  margin-bottom: 20px;
}
.bd-service-details-feature ul li:last-child {
  margin-bottom: 0px;
}
.bd-service-details-feature ul li i {
  color: var(--clr-theme-1);
  margin-right: 8px;
}
.bd-service-details-feature-img img {
  border-radius: 10px;
  width: 100%;
}
.bd-service-details .bd-service {
  border: 1px solid var(--clr-bg-gray2);
}
.bd-service-details .bd-service-title {
  font-size: 22px;
  font-weight: 600;
}

.bd-service-widget {
  padding: 30px 30px 30px 30px;
  border-radius: 10px;
}

.bd-service-sidebar-category-title {
  font-size: 20px;
  font-weight: 600;
  color: var(--clr-body-heading);
  line-height: 1;
}

.bd-service-sidebar-category ul li {
  list-style: none;
  margin-bottom: 5px;
}
.bd-service-sidebar-category ul li:last-child {
  margin-bottom: 0px;
}
.bd-service-sidebar-category ul li a {
  height: 60px;
  line-height: 60px;
  padding: 0px 30px;
  display: block;
  font-size: 16px;
  font-weight: 600;
  color: var(--clr-body-heading);
  background: var(--clr-common-white);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 575px) {
  .bd-service-sidebar-category ul li a {
    padding: 0px 20px;
  }
}
.bd-service-sidebar-category ul li a i {
  float: right;
  line-height: 60px;
}
.bd-service-sidebar-category ul li a:hover {
  background: var(--clr-theme-1);
  color: var(--clr-common-white);
}

.bd-service-sidebar-support-title {
  font-size: 24px;
  color: var(--clr-common-white);
  font-weight: 600;
  line-height: 1.4;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 575px) {
  .bd-service-sidebar-support-title br {
    display: none;
  }
}

.bd-service-sidebar-info span {
  font-size: 14px;
  display: block;
  color: var(--clr-body-text);
  margin-bottom: 5px;
}
.bd-service-sidebar-info h6 {
  margin-bottom: 0px;
  font-size: 16px;
  font-weight: 500;
  color: var(--clr-body-heading);
}

.bd-service-sidebar-btn .theme-btn-black {
  display: block;
  text-align: center;
}
.bd-service-sidebar-btn .theme-btn-black i {
  margin-left: 3px;
}

.bd-case-studies-section1{
  width:33.33%;
  padding-right:35px;
}
.bd-case-studies-section3{
  width:23%;
  
}
.bd-case-studies-section2{
  width:43.66%;
}

.bd-service-details .bd-section-title {
  font-size: 48px;
  line-height: 1.16;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-service-details .bd-section-title {
    font-size: 42px;
  }
}
@media (max-width: 767px) {
  .bd-service-details .bd-section-title {
    font-size: 36px;
  }
}

.micro-service-tittle{
font-size:46px;
}

@media only screen and (min-width:350px) and (max-width:500px){
  .micro_respon{
    padding-top: 100px;
  }
}

@media (max-width: 767px){
  .micro-service-tittle{
    font-size:36px;
  }
}

.link-service {
color:var(--clr-body-heading)
}
@media only screen and (min-width:350px) and (max-width:991px){
  .main-top-service{
    padding-bottom: 0px;
  }
}

