.first_pop_hosting{
    position: relative;
    bottom:360px;
    left:300px;
    z-index: 3;
  }

  @media only screen and (min-width: 1400px) and (max-width: 1450px){
    .first_pop_hosting{
        left:calc(22% + 20px);
    }
}
@media only screen and (min-width: 1451px) and (max-width: 1500px){
  .first_pop_hosting{
      left:calc(22% + 30px);

  }
}
@media only screen and (min-width: 1501px) and (max-width: 1550px){
  .first_pop_hosting{
      left:calc(22% + 50px);
  }
}
@media only screen and (min-width: 1551px) and (max-width: 1659px){
  .first_pop_hosting{
      left:calc(25% + 20px);

  }
}

@media only screen and (min-width: 1660px) and (max-width: 1730px){
  .first_pop_hosting{
      left:calc(25% + 40px);
  }
}
@media only screen and (min-width: 1731px) and (max-width: 1800px){
  .first_pop_hosting{
      left:calc(25% + 70px);
  }
}

@media only screen and (min-width: 350px) and (max-width: 500px){
  .top-resp{
    padding-top: 100px
  }
}

@media only screen and (min-width: 350px) and (max-width: 991px) {
  .respon_host{
    flex-direction: column-reverse;
  }
}

@media only screen and (min-width: 350px) and (max-width: 400px) {

  .play_transition{
      font-size:14px;
      line-height:20px;
      margin-bottom: 18px;
      margin-left:2px;
  }
  .touch_pop_vid{
      position:absolute;
      top:718px;
      left:calc(35% + 10px);
      width:65px;
      height:65px;
      border: 15px solid var(--clr-common-white);
  }
}

@media only screen and (min-width: 401px) and (max-width: 450px) {
  .play_transition{
      font-size:14px;
      line-height:20px;
      margin-bottom: 18px;
      margin-left:2px;
  }
  .touch_pop_vid{
      position:absolute;
      top:720px;
      left:calc(37% + 10px);
      width:65px;
      height:65px;
      border: 15px solid var(--clr-common-white);
  }
}

@media only screen and (min-width: 451px) and (max-width: 500px) {
  .play_transition{
      font-size:14px;
      line-height:20px;
      margin-bottom: 16px;
      margin-left:2px;
  }
  .touch_pop_vid{
      position:absolute;
      top:730px;
      left:calc(37% + 20px);
      width:72px;
      height:72px;
      border: 15px solid var(--clr-common-white);
  }
}

@media only screen and (min-width: 501px) and (max-width: 550px) {
  .play_transition{
      font-size:14px;
      line-height:20px;
      margin-bottom: 16px;
      margin-left:2px;
  }
  .touch_pop_vid{
      position:absolute;
      top:655px;
      left:calc(37% + 20px);
      width:80px;
      height:80px;

  }
  .bd-choose-img .bd-choose-shape-2{
      position: absolute;
      left:180px;
      width:120px;
  }
}

@media only screen and (min-width: 551px) and (max-width: 600px) {
  .play_transition{
      font-size:14px;
      line-height:20px;
      margin-bottom: 16px;
      margin-left:2px;
  }
  .touch_pop_vid{
      position:absolute;
      top:680px;
      left:calc(37% + 20px);
      width:80px;
      height:80px;

  }
  .bd-choose-img .bd-choose-shape-2{
      position: absolute;
      left:180px;
      width:120px;
  }
}

@media only screen and (min-width: 601px) and (max-width: 650px) {
  .touch_pop_vid{
      position:absolute;
      top:690px;
      left:calc(37% + 20px);
  }
}

@media only screen and (min-width: 600px) and (max-width: 767px) {
  .resp-img{
      width:100%
  }
}

@media only screen and (min-width: 651px) and (max-width: 720px) {
  .touch_pop_vid{
      position:absolute;
      top:720px;
      left:calc(37% + 20px);
  }
}

@media only screen and (min-width: 721px) and (max-width: 768px) {
  .touch_pop_vid{
      position:absolute;
      top:745px;
      left:calc(38% + 30px);
  }
}

@media only screen and (min-width: 768px) and (max-width: 830px) {
  .touch_pop_vid{
      position:absolute;
      top:690px;
      left:calc(30% + 10px);
  }
}
@media only screen and (min-width: 830px) and (max-width: 920px) {
  .touch_pop_vid{
      position:absolute;
      top:690px;
      left:calc(28% + 0px);
  }
}
@media only screen and (min-width: 921px) and (max-width: 960px) {
  .touch_pop_vid{
      position:absolute;
      top:690px;
      left:calc(26% + 0px);
  }
}
@media only screen and (min-width: 961px) and (max-width: 991px) {
  .touch_pop_vid{
      position:absolute;
      top:690px;
      left:calc(25% + 0px);
  }
}

@media only screen and (min-width: 991px) and (max-width: 1100px) {
  .touch_pop_vid{
    position:absolute;
    top:630px;
    left:calc(19% + 0px);
}
}

@media only screen and (min-width: 1100px) and (max-width: 1200px) {
  .touch_pop_vid{
    position:absolute;
    top:670px;
    left:calc(20% + 0px);
}
}
@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .touch_pop_vid{
    position:absolute;
    top:700px;
    left:calc(20% + 0px);
}
}