.bd-blog {
  border-radius: 10px;
  /* overflow: hidden; */
}
.bd-blog img {
  width: 100%;
}
.bd-blog .bd-blog-img {
  overflow: hidden;
}
.bd-blog:hover .bd-blog-img img {
  transform: scale3d(1.1, 1.1, 1.1);
}

.bd-blog-meta ul li {
  display: inline-block;
  list-style: none;
  margin-right: 35px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 575px) {
  .bd-blog-meta ul li {
    margin-right: 10px;
  }
}
.bd-blog-meta ul li:last-child {
  margin-right: 0px;
}
.bd-blog-meta ul li a {
  font-size: 14px;
  font-weight: 500;
  color: var(--clr-body-text);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  text-transform: uppercase;
}
.bd-blog-meta ul li a:hover {
  color: var(--clr-theme-1);
}
.bd-blog-meta ul li a i {
  margin-right: 8px;
  transform: translateY(2px);
  color: var(--clr-theme-1);
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  (max-width: 575px) {
  .bd-blog-meta ul li a i {
    margin-right: 5px;
  }
}

.bd-blog-text {
  padding: 25px 35px 30px 35px;
  background-color: var(--clr-bg-gray);
}
@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .bd-blog-text {
    padding: 25px 30px 30px 30px;
  }
}

.bd-blog-text-six {
  background-color: var(--clr-common-white);
}

.bd-blog-title {
  font-size: 22px;
  line-height: 1.45;
  font-weight: 600;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bd-blog-title {
    font-size: 18px;
  }
}
.bd-blog-title a:hover {
  color: var(--clr-theme-1);
}

.bd-blog-author {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bd-blog-author-info {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.bd-blog-author-info img {
  height: 30px;
  width: 30px;
  border-radius: 50%;
  border: 2px solid var(--clr-common-white);
  margin-right: 8px;
}

.bd-blog-author-info-title {
  font-size: 14px;
  font-weight: 500;
  color: var(--clr-body-text);
  line-height: 1;
  margin-bottom: 0px;
}

.bd-blog-author-link a {
  font-size: 14px;
  font-weight: 500;
  color: var(--clr-body-text);
  text-transform: uppercase;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.bd-blog-author-link a:hover {
  color: var(--clr-theme-1);
}
.bd-blog-author-link a:hover i {
  padding-left: 3px;
  display: inline-block;
}

.basic-pagination ul li {
  display: inline-block;
  margin-right: 10px;
}
.basic-pagination ul li a,
.basic-pagination ul li span {
  position: relative;
  overflow: hidden;
  background: var(--clr-bg-gray);
  color: var(--clr-body-heading);
  font-size: 20px;
  display: inline-block;
  width: 44px;
  height: 44px;
  line-height: 44px;
  text-align: center;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}

.basic-pagination ul li a:hover,
.basic-pagination ul li span.current {
  background: var(--clr-theme-1);
  color: var(--clr-common-white);
}

.sidebar--widget__search form {
  position: relative;
}
.sidebar--widget__search form input {
  width: 100%;
  height: 60px;
  line-height: 60px;
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  border: none;
  outline: none;
  padding: 0 20px;
  background: var(--clr-bg-gray);
  color: var(--clr-body-text);
}
.sidebar--widget__search form button {
  position: absolute;
  top: 0;
  right: 0;
  height: 60px;
  width: 50px;
  line-height: 60px;
  color: var(--clr-theme-1);
  background: transparent;
  border: none;
  outline: none;
  cursor: pointer;
}

.sidebar--widget__post {
  display: flex;
}
.sidebar--widget__post .post__img {
  height: 90px;
  width: 80px;
  border-radius: 10px;
  background-size: cover;
  background-position: left;
  margin-right: 15px;
}
.sidebar--widget__post .sidebar__post--text {
  overflow: hidden;
}

.sidebar__post--title {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 600;
}
.sidebar__post--title:hover {
  color: var(--clr-theme-1);
}

.sidebar__widget--title {
  font-size: 20px;
  position: relative;
  padding-bottom: 10px;
  font-weight: 600;
}
.sidebar__widget--title:before {
  position: absolute;
  content: "";
  left: 0;
  bottom: 0;
  width: 60px;
  height: 2px;
  background: var(--clr-theme-1);
}

.sidebar--widget__cat ul li {
  margin-bottom: 15px;
  list-style: none;
}
.sidebar--widget__cat ul li:last-child {
  margin-bottom: 0px;
}
.sidebar--widget__cat ul li a {
  display: inline-block;
  position: relative;
  padding-left: 20px;
  color: var(--clr-body-text);
  font-size: 16px;
}
.sidebar--widget__cat ul li a :before {
  position: absolute;
  /* content: "\f061"; */
  left: 0;
  top: 50%;
  transform: translateY(-50%);
  font-family: "Font Awesome 5 Pro";
  font-size: 14px;
  color: var(--clr-theme-1);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.sidebar--widget__cat ul li a:hover {
  color: var(--clr-theme-1);
}
.sidebar--widget__cat ul li a:hover:before {
  left: 7px;
}

.sidebar--widget__tag a {
  display: inline-block;
  height: 34px;
  line-height: 30px;
  text-align: center;
  padding: 0 19px;
  font-size: 16px;
  border: 2px solid var(--clr-bg-gray2);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin-right: 8px;
  margin-bottom: 10px;
  color: var(--clr-body-text);
}
.sidebar--widget__tag a:hover {
  background: var(--clr-theme-1);
  color: var(--clr-common-white);
  border-color: var(--clr-theme-1);
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .ablog__sidebar--wrapper {
    margin-right: 0px;
  }
}

.ablog__text4 blockquote {
  padding: 40px;
  color: var(--clr-body-text);
  position: relative;
  background: var(--clr-bg-gray);
  font-style: normal;
  text-align: left;
  clear: both;
  font-weight: 400;
  border-radius: 10px;
  box-shadow: 0px 1px 2px 0px rgba(10, 0, 58, 0.14);
  border: 1px solid var(--clr-bg-gray2);
  margin-bottom: 30px;
  padding-bottom: 25px;
}
@media (max-width: 767px) {
  .ablog__text4 blockquote {
    padding: 25px;
    padding-bottom: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .ablog__text4 blockquote {
    padding: 40px;
    padding-bottom: 25px;
  }
}
.ablog__text4 blockquote:before {
  content: "";
  position: static;
  font-family: "Font Awesome 5 Pro";
  font-size: 32px;
  color: var(--clr-theme-1);
  line-height: 1;
  margin-bottom: 18px;
  display: inline-block;
}

.icon {
  font-size: 32px;
  color: var(--clr-theme-1);
  margin-bottom: 18px;
}
.ablog__text4 blockquote cite {
  font-size: 16px;
  font-weight: 500;
  display: block;
  margin-top: 10px;
  color: var(--clr-body-heading);
  font-style: inherit;
}
.ablog__text4 blockquote cite:before {
  content: "";
  display: inline-block;
  background: var(--clr-theme-1);
  height: 2px;
  width: 40px;
  top: -4px;
  margin-right: 10px;
  position: relative;
}

.blog__deatails--tag span {
  font-size: 16px;
  margin-right: 10px;
  margin-bottom: 0px;
  font-weight: 600;
  color: var(--clr-body-heading);
}
@media (max-width: 767px) {
  .blog__deatails--tag span {
    display: block;
    margin-bottom: 10px;
    margin-right: 0px;
  }
}
.blog__deatails--tag a {
  display: inline-block;
  height: 34px;
  line-height: 30px;
  text-align: center;
  padding: 0 19px;
  font-size: 14px;
  border: 2px solid var(--clr-bg-gray2);
  -webkit-border-radius: 6px;
  -moz-border-radius: 6px;
  border-radius: 6px;
  margin-right: 8px;
}
@media (max-width: 767px) {
  .blog__deatails--tag a {
    margin-bottom: 10px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog__deatails--tag a {
    margin-bottom: 0px;
  }
}
.blog__deatails--tag a:hover {
  border-color: var(--clr-theme-1);
  color: var(--clr-common-white);
  background: var(--clr-theme-1);
}

.blog__details--subtitle {
  font-size: 35px;
  margin-bottom: 15px;
  font-weight: 600;
}

.blog__author {
  padding: 30px 40px;
  -webkit-box-shadow: 0px 1px 4px 0px rgba(10, 0, 58, 0.14);
  -moz-box-shadow: 0px 1px 4px 0px rgba(10, 0, 58, 0.14);
  box-shadow: 0px 1px 4px 0px rgba(10, 0, 58, 0.14);
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
}
@media (max-width: 767px) {
  .blog__author {
    padding-bottom: 18px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .blog__author {
    padding-bottom: 30px;
    padding-bottom: 20px;
  }
}
@media (max-width: 575px) {
  .blog__author {
    text-align: center;
    padding: 30px 30px;
  }
}
@media (max-width: 767px) {
  .blog__author-img {
    margin-bottom: 15px;
  }
}
@media (max-width: 575px) {
  .blog__author-img {
    margin-right: 0px;
  }
}

.blog__author-content h5 {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 600;
}
.blog__author-content span {
  display: inline-block;
  margin-bottom: 5px;
}
.blog__author-content p {
  margin-bottom: 0px;
}

.post-comment-title h3 {
  font-size: 20px;
  font-weight: 600;
}

.latest-comments ul {
  list-style: none;
}

.latest-comments li:first-child .comments-box {
  border-top: 0;
  padding-top: 0;
}

.comments-avatar {
  float: left;
  margin-right: 20px;
}
.comments-avatar img {
  border-radius: 50%;
}

.comments-text {
  overflow: hidden;
  border-bottom: 1px solid var(--clr-bg-gray2);
  padding-bottom: 30px;
  margin-bottom: 30px;
}
.comments-text p {
  margin-bottom: 20px;
}

.comment-reply {
  font-weight: 500;
  font-size: 14px;
  color: var(--clr-body-heading);
}
.comment-reply:hover {
  color: var(--clr-theme-1);
}

.avatar-name {
  margin-bottom: 10px;
  overflow: hidden;
  position: relative;
}
.avatar-name h5 {
  font-size: 16px;
  margin-bottom: 5px;
  font-weight: 600;
}
.avatar-name span {
  font-size: 14px;
  font-weight: 500;
}

.ablog-4 {
  border-radius: 10px;
  overflow: hidden;
  -webkit-box-shadow: 0px 1px 2px 0px rgba(10, 0, 58, 0.14);
  -moz-box-shadow: 0px 1px 2px 0px rgba(10, 0, 58, 0.14);
  box-shadow: 0px 1px 2px 0px rgba(10, 0, 58, 0.14);
}

.ablog__text4 {
  padding: 20px 0px 0px 0px;
  border-top: 0px;
}
@media (max-width: 575px) {
  .ablog__text4 {
    padding: 20px 10px 20px 10px;
  }
}

.latest-comments li.children {
  margin-left: 105px;
}
@media (max-width: 767px) {
  .latest-comments li.children {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .latest-comments li.children {
    margin-left: 70px;
  }
}

.post-comment-form h4 {
  font-size: 24px;
  margin-bottom: 7px;
  font-weight: 600;
}

.post-comment-form > span {
  display: inline-block;
  margin-bottom: 45px;
}

.bd-blog-details-title {
  font-size: 48px;
  font-weight: 700;
  line-height: 1.16;
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-blog-details-title {
    font-size: 42px;
  }
}
@media (max-width: 767px) {
  .bd-blog-details-title {
    font-size: 36px;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .blog__details--wrapper.mr-50 {
    margin-right: 0px;
  }
}

@media only screen and (min-width: 350px) and (max-width: 600px) {
  .bd-responisve-pagination {
    display: none;
  }
}
.bd-cta-audio {
  padding: 15px;
  background-color: #c32948;
  border-radius: 5px;
}

.bg-blog-ul li{
  color: var(--clr-body-text) !important;
}