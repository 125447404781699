/* Fact css
/*----------------------------------------*/
.bd-fact .bd-fact-subtitle {
    font-size: 16px;
    display: block;
  }
  .bd-fact-inner {
    text-align: center;
  }
  
  .bd-fact-title,
  .bd-fact-title .odometer {
    font-size: 60px;
    line-height: 1.16;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .bd-fact-title,
  .bd-fact-title .odometer {
      font-size: 50px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .bd-fact-title,
  .bd-fact-title .odometer {
      font-size: 46px;
    }
  }
  @media (max-width: 767px) {
    .bd-fact-title,
  .bd-fact-title .odometer {
      font-size: 36px;
    }
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .bd-fact-about-spacing {
      padding-top: 170px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .bd-fact-about-spacing {
      padding-top: 110px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .bd-fact-about-spacing {
      padding-top: 110px;
    }
  }
  
  .bd-slider-facts ul li {
    list-style: none;
    display: inline-block;
    margin-right: 60px;
  }
  @media (max-width: 767px) {
    .bd-slider-facts ul li {
      margin-right: 20px;
    }
  }
  @media (max-width: 575px) {
    .bd-slider-facts ul li {
      margin-right: 15px;
      max-width: 29%;
    }
  }
  .bd-slider-facts ul li:last-child {
    margin-right: 0px;
  }
  .bd-slider-facts .bd-slider-fact-title,
  .bd-slider-facts .bd-slider-fact-title .odometer-value {
    font-size: 30px;
    font-weight: 600;
    margin-bottom: 0px;
  }
  @media (max-width: 575px) {
    .bd-slider-facts .bd-slider-fact-title,
  .bd-slider-facts .bd-slider-fact-title .odometer-value {
      font-size: 24px;
    }
  }
  .bd-slider-facts .bd-slider-fact-subtitle {
    font-size: 16px;
  }
  @media (max-width: 575px) {
    .bd-slider-facts .bd-slider-fact-subtitle {
      font-size: 12px;
    }
  }
  
  @media (max-width: 767px) {
    .bd-fact-area.pt-145 {
      padding-top: 110px;
    }
  }
  
  @media (max-width: 767px) {
    .bd-fact-area-four.pt-105 {
      padding-top: 110px;
    }
  }
  
  @media (max-width: 575px) {
    .bd-slider-facts-six ul li {
      max-width: 44%;
    }
  }
  
  @media (max-width: 767px) {
    .bd-slider-video-spacing.mb-90 {
      margin-bottom: 60px;
    }
  }
  
  @media (max-width: 767px) {
    .bd-slider-six-img {
      margin-top: 70px;
    }
  }
  
  /*----------------------------------------*/
  /*  13. Team
  /*----------------------------------------*/
  .bd-team-four span {
    font-size: 15px;
    color: var(--clr-common-white);
    display: inline-block;
    margin-bottom: 20px;
  }
  .bd-team-four .bd-team-four-text {
    position: absolute;
    left: 0px;
    right: 0px;
    margin: 0 auto;
    bottom: -100%;
    -webkit-transition: all 0.5s ease-out 0s;
    -moz-transition: all 0.5s ease-out 0s;
    -ms-transition: all 0.5s ease-out 0s;
    -o-transition: all 0.5s ease-out 0s;
    transition: all 0.5s ease-out 0s;
    text-align: center;
    z-index: 2;
  }
  .bd-team-four:hover .bd-team-four-text {
    bottom: 30px;
  }
  
  .bd-team-four-title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 5px;
    color: var(--clr-common-white);
  }
  
  .bd-team-four-social ul li {
    display: inline-block;
    list-style: none;
    margin: 0px 5px;
  }
  .bd-team-four-social ul li a {
    height: 36px;
    width: 36px;
    line-height: 36px;
    display: inline-block;
    border-radius: 3px;
    font-size: 14px;
    color: var(--clr-common-white);
    text-align: center;
    background: rgba(22, 22, 22, 0.22);
  }
  .bd-team-four-social ul li a:hover {
    background: var(--clr-theme-1);
  }

  .fact-responsive{
padding-top:170px;
padding-bottom: 10px;
  }

  @media only screen and (min-width:350px) and (max-width:991px){
    .fact-responsive{
      padding-top: 0px;
      padding-bottom:0px;
    }
  }