.case-study-section{
    width:100%
}
.main-image1{
    width:100%;
    height:540px
}
.case-point-sec{
    font-size:16px;
    color:var(--clr-body-text);

}

.bd-service1{
 border-radius: 5px;
background: var(--clr-common-white);
padding: 38px 35px 30px 35px;
min-height: calc(100% - 30px);
transition: all 0.3s ease-out 0s;
}

.case-study-boxes{
    text-align: center;
}

.bd-service1:hover i{
    animation-name: wobble-vertical;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 3;
}
.case-studie-button{
    text-align: center;
}

.size-button{
    width:200px;
}