.bd-page-title-bg2 {
    position: relative;
    background-color: var(--clr-bg-gray);
  }
  .bd-page-title-bg2:before {
    position: absolute;
    content:"";
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;

    background: linear-gradient(to bottom, rgba(96, 8, 248, 0.2) 0%, rgba(96, 8, 248, 0.45) 100%);
    /* background: -moz-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: -webkit-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: linear-gradient(to right, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%); */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#1aef0454", endColorstr="#1a6008f8",GradientType=1 );
  }

  .first_pop_video_business{
    position: relative;
    bottom: 400px;
    left: calc(70% + 50px);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    display: inline-block;
    z-index: 3;
    }

    @media only screen and (min-width: 1400px) and (max-width: 1569px){
      .first_pop_video_business{
          left:calc(70% + 30px);
          bottom: 480px;
      }
  }
  @media only screen and (min-width: 1570px) and (max-width: 1700px) {
      .first_pop_video_business{
          left:calc(70% + 0px);
          bottom: 480px;
      }
  }
  
  @media only screen and (min-width: 1701px) and (max-width: 1850px) {
      .first_pop_video_business{
          left:calc(68% + 0px);
          bottom: 480px;
      }
  }

  

  .first_section_para1{
    width:95%
  }
  .second_section_Business1{
    width:45%;
  }
  .second_section_Business2{
    width:55%;
  }
  .second_sec_tittle{
    font-size:46px;
  }
  .third_sec_tittle{
    font-size:46px;
  }
  .fourth_sec_tittle{
    font-size:46px;
    margin-top: 35px;
  }
  .first_part_respons{
    width:32%;
  }
  .second_part_respons{
    width:68%;
  }
  .sixth_sec_tittle{
    font-size:46px;
  }
  .Business_fifth_flex1{
    width:60%;
  }
  .Business_fifth_flex2{
    width:40%;
  }
  .sixth_sec1_resp{
    width:45%;
  }
  .sixth_sec2_resp{
    width:55%;
  }
  
  .business-last-tittle{
    font-size:46px
  }

@media (max-width: 767px){
.first_section-title{
    font-size: 36px;
}
.first_section_para1{
    width:100%
}
.second_sec_tittle{
    font-size:36px;
}
.third_sec_tittle{
    font-size:36px;
    text-align: left;
}
.fourth_sec_tittle{
    font-size:36px;
}
.sixth_sec_tittle{
    font-size:36px
}
.business-last-tittle{
  font-size:36px
}

}

@media only screen and (min-width:350px) and (max-width:800px){
    .second_section_Business1{
        width:100%
    }
    .second_section_Business2{
        width:100%
    }
    .first_part_respons{
        width:100%
    }
    .second_part_respons{
        width:100%
    }
    .Business_fifth_flex1{
        width:100%
    }
    .Business_fifth_flex2{
        width:100%
    }
    .sixth_sec1_resp{
      width:100%;
    }
    .sixth_sec2_resp{
      width:100%
    }
    .business-resp{
      margin-right: 30px;
    }
   
  
}
@media only screen and (min-width:350px) and (max-width:800px){
.fourt_respon_business{
    flex-direction: column;
}
.fifth_section_responsive{
    flex-direction: column;
}
.Business_resp_section{
    flex-direction: column;
}


}
