/*  Header */
.bd-header-area-lg {
  position: absolute;
  top: 0px;
  right: 0px;
  width: 100%;
  z-index: 9;
  background-color: transparent;
}
.bd-header-right .css-1hb7zxy-IndicatorsContainer {
  display: none !important;
}
.bd-header-right .css-1wy0on6 {
  display: none !important;
}
.bd-header-right .css-13cymwt-control {
  background-color: transparent !important ;
  border: none !important;
}

.respon {
  width: 13%;
}

.respons2 {
  width: 75%;
}
.nav-padding {
  padding: 15px 0px !important;
}

@media screen and (min-width: 1200px) {
  .scrolled {
    background-color: rgba(92, 3, 133, 0.82);
    border: none !important;
    color: #fff !important;
  }
  .scrolled li a {
    color: #fff !important;
  }
}

.align-items-center {
  width: 100%;
}

.bd-spacing {
  padding-left: 10px;
  padding-right: 10px;
}

@media (min-width: 1440px) and (max-width: 1366px) {
  .align-items-center {
    width: 100%;
  }
  .bd-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }
  .txt-cent {
    text-align: center;
    justify-content: center;
  }
}

@media (min-width: 961px) and (max-width: 1025px) {
  .align-items-center {
    width: 100%;
  }
  .bd-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }
  .txt-cent {
    text-align: center;
    justify-content: center;
  }
}
@media (min-width: 761px) and (max-width: 960px) {
  .align-items-center {
    width: 100%;
  }
  .bd-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }
  .txt-cent {
    text-align: center;
    justify-content: center;
  }
}
@media (min-width: 642px) and (max-width: 760px) {
  .align-items-center {
    width: 100%;
  }
  .bd-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }
  .txt-cent {
    text-align: center;
    justify-content: center;
  }
}
@media (min-width: 481px) and (max-width: 641px) {
  .align-items-center {
    width: 100%;
  }
  .bd-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }
  .txt-cent {
    text-align: center;
    justify-content: center;
  }
}
@media (min-width: 301px) and (max-width: 480px) {
  .align-items-center {
    width: 100%;
  }
  .bd-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }
  .txt-cent {
    text-align: center;
    justify-content: center;
  }
}
@media (min-width: 1441px) and (max-width: 1600px) {
  .align-items-center {
    width: 100%;
  }
  .bd-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }
  .txt-cent {
    text-align: center;
    justify-content: center;
  }
}
@media (min-width: 1601px) and (max-width: 1800px) {
  .align-items-center {
    width: 100%;
  }
  .bd-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }
  .txt-cent {
    text-align: center;
    justify-content: center;
  }
}
@media (min-width: 1801px) and (max-width: 2000px) {
  .align-items-center {
    width: 100%;
  }
  .bd-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }
  .txt-cent {
    text-align: center;
    justify-content: center;
  }
}

@media (min-width: 2001px) and (max-width: 2300px) {
  .align-items-center {
    width: 100%;
  }
  .bd-spacing {
    padding-left: 10px;
    padding-right: 10px;
  }
  .txt-cent {
    text-align: center;
    justify-content: center;
  }
}

.nav1 {
  position: fixed;
  top: 0px;
}

.sticky-nav {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99999;
}

.bd-header-area.active {
  position: fixed;
  width: 100%;
  z-index: 999;
  color: black;
  background-color: white;
  animation: 800ms ease-in-out 0s normal none 1 running fadeInDown;
  box-shadow: 0 0 10px 3px rgba(0, 0, 0, 0.05);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

/* @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .bd-header-spacing {
    padding: 20px 0px;
  }
} */

.bd-main-menu ul li {
  list-style: none;
  display: inline-block;
  margin-right: 20px;
  position: relative;
  /*submenu start*/
  /*submenu end*/
}

@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .bd-main-menu ul li {
    margin-right: 10px;
  }
}
@media only screen and (min-width: 1401px) and (max-width: 1900px) {
  .respons2 {
    width: 73% !important;
  }
  .respons2 ul {
    text-align: center;
  }
  .bd-lap-resp {
    width: 12% !important;
  }
  .bd-lap-resp-home {
    width: 13% !important;
  }
}

/* @media (max-Width:900px) {
  .bd-main-menu{
    display:none
  }

  .bd-header-btn{
    position: relative;
    right:0px
  }

  .bd-header-right {
    display: flex;
  justify-content: end;
  align-items: center;
  width:550px;
  margin-left: 180px;
  }

  .mobile-menu-bar{
    margin-left:40px;
  }

  .bd-custom-container{
    padding-top: 20px;
  }

  .bd-main-menu ul li .responsive{
  list-style: none;
  display: block;
  margin-right: 20px;
  position: relative;
  }


}

@media (min-width:900px) {
  .mobile-menu-bar{
    display:none
  }
} */

@media (min-width: 2500px) {
  .bd-main-menu ul li {
    padding-right: 0px;
  }
}

.bd-main-menu ul li:last-child {
  margin-right: 0px;
}
.bd-main-menu ul li a {
  font-size: 14.5px;
  font-weight: 600;
  /* color: var(--clr-common-white); */
  display: block;
  /* padding: 43px 0px; */
  padding: 15px 0px;
  transition: all linear 0.3s;
  position: relative;
}
.bd-main-menu ul li a:hover,
.bd-main-menu ul li a.active {
  color: #fdd796 !important;
}
.bd-main-menu ul li.menu-item-has-children > a:after {
  margin-left: 0px;
  font-family: "Font Awesome 5 Pro";
  font-size: 12px;
  color: var(--clr-common-white);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.bd-main-menu ul li.menu-item-has-children > a:hover:after,
.bd-main-menu ul li.menu-item-has-children > a.active:after {
  color: var(--clr-theme-1);
}
.bd-main-menu ul li .sub-menu {
  background: var(--clr-common-white) none repeat scroll 0 0;
  box-shadow: 0 6px 12px rgba(0, 0, 0, 0.176);
  left: 0;
  opacity: 0;
  position: absolute;
  top: 119%;
  transition: all 0.3s ease 0s;
  visibility: hidden;
  width: 240px;
  z-index: 9;
  border-top: 4px solid var(--clr-theme-1);
  text-align: left;
  padding: 15px 0;
}
.bd-main-menu ul li .sub-menu li {
  display: block;
  margin: 0px;
  padding: 8px 25px 8px 25px;
}
.bd-main-menu ul li .sub-menu li a {
  padding: 0px;
  display: inline-block;
  color: #212237;
  position: relative;
}
.bd-main-menu ul li .sub-menu li a:after {
  display: none;
}
.bd-main-menu ul li .sub-menu li a:before {
  content: "";
  width: 0;
  height: 1px;
  bottom: 0;
  position: absolute;
  left: auto;
  right: 0;
  z-index: -1;
  transition: width 0.6s cubic-bezier(0.25, 0.8, 0.25, 1) 0s;
  background: var(--clr-theme-1);
}
.bd-main-menu ul li .sub-menu li:hover a {
  color: var(--clr-theme-1);
}
.bd-main-menu ul li .sub-menu li:hover a:before {
  width: 100%;
  left: 0;
  right: auto;
}
/* .bd-main-menu ul li:hover a, .bd-main-menu ul li:hover a:after {
  color: var(--clr-theme-1);
} */
.bd-main-menu ul li:hover .sub-menu {
  opacity: 1;
  visibility: visible;
  top: 100%;
}

.bd-header-right {
  display: flex;
  justify-content: end;
  align-items: center;
}
.bd-control-flag:focus {
  box-shadow: none;
}
.no-gutter {
  --bs-gutter-x: 0rem;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .bd-header-right {
    margin-left: -30px;
  }
  .flag-inca {
    margin-left: 0px !important;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1235px) {
  .bd-header-right {
    margin-right: -25px;
  }
}

.bd-header-icon {
  margin-right: 30px;
}
@media (max-width: 575px) {
  .bd-header-icon {
    margin-right: 0px;
  }
}
.bd-header-icon span {
  height: 40px;
  width: 40px;
  line-height: 38px;
  text-align: center;
  color: var(--clr-common-white);
  border-radius: 50%;
  display: inline-block;
  border: 1px solid var(--clr-border-2);
  font-size: 14px;
  cursor: pointer;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}

.bd-header-social {
  margin-right: 60px;
}
/* @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .bd-header-social {
    display: none;
  }
} */
.bd-header-social a {
  font-size: 16px;
  color: var(--clr-common-white);
  display: inline-block;
  margin-right: 25px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .bd-header-social a {
    margin-right: 14px;
  }
}
.bd-header-social a:hover {
  color: var(--clr-theme-1);
}
.bd-header-social a:last-child {
  margin-right: 0px;
}

.bd-header-btn {
  display: inline-flex;
  align-items: center;
}
@media (max-width: 575px) {
  .bd-header-btn {
    display: none;
  }
}

.bd-main-menu-border {
  margin-left: -40px;
  position: relative;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .bd-main-menu-border {
    margin-left: -5px;
  }
}
.bd-main-menu-border:before {
  position: absolute;
  content: "";
  top: 0px;
  left: -58px;
  height: 100%;
  width: 1px;
  background-color: var(--clr-border-1);
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .bd-main-menu-border:before {
    left: -30px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .bd-main-menu-border:before {
    display: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px),
  only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
}

.bd-mobile-menu.mean-container {
  clear: both;
  overflow: hidden;
}

.contact-infos {
  margin-top: 30px;
}
.contact-infos h4 {
  font-size: 27px;
  color: var(--clr-common-white);
  margin-bottom: 20px;
}
.contact-infos ul li {
  font-size: 15px;
  color: var(--clr-common-white);
  margin-bottom: 15px;
  list-style: none;
}
.contact-infos ul li:last-child {
  margin-bottom: 0px;
}
.contact-infos ul li i {
  color: var(--clr-theme-1);
  vertical-align: middle;
  width: 25px;
  display: inline-block;
}

.sidebar__menu--social {
  margin-top: 25px;
}
.sidebar__menu--social a {
  height: 40px;
  width: 40px;
  line-height: 40px;
  text-align: center;
  font-size: 14px;
  display: inline-block;
  margin-right: 5px;
  background: #393f53;
  color: var(--clr-common-white);
  position: relative;
  z-index: 1;
  overflow: hidden;
  border-radius: 5px;
}
.sidebar__menu--social a:before {
  position: absolute;
  content: "";
  top: 0px;
  left: 0px;
  height: 100%;
  width: 0%;
  background: var(--clr-theme-1);
  z-index: -1;
  transform: rotate(90deg);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.sidebar__menu--social a:hover:before {
  width: 100%;
}
.sidebar__menu--social a:hover i {
  transform: rotate(360deg);
}
.sidebar__menu--social a:last-child {
  margin-right: 0px;
}

.bd-main-menu-border-two::before {
  display: none;
}

.bd-main-menu-three ul li a,
.bd-main-menu-breadcrumb ul li a {
  color: var(--clr-body-heading);
}

.bd-main-menu-three ul li.menu-item-has-children > a::after,
.bd-main-menu-breadcrumb ul li.menu-item-has-children > a::after {
  color: var(--clr-body-heading);
}

.bd-main-menu-three ul li.menu-item-has-children:hover > a::after,
.bd-main-menu-breadcrumb ul li.menu-item-has-children:hover > a::after {
  color: var(--clr-theme-1);
}

.d-header-icon-three {
  margin-right: 0px;
}
.d-header-icon-three span {
  background: var(--clr-body-heading);
  border-color: var(--clr-body-heading);
  height: 60px;
  width: 60px;
  line-height: 57px;
  font-size: 24px;
}

.side-menu-icon-two.side-menu-icon i {
  color: var(--clr-body-heading);
}

.bd-header-area-four.header-sticky.sticky-menu,
.bd-header-area-breadcrumb.header-sticky.sticky-menu,
.bd-main-menu-border-five.header-sticky.sticky-menu {
  background: var(--clr-common-white);
}

.bd-header-area-four {
  position: relative;
}
.bd-header-area-four .bd-header-border {
  border-bottom: none;
}

.bd-main-menu-border-four.bd-main-menu-border::before {
  background: var(--clr-bg-gray2);
}

.bd-main-menu-border-four ul li a,
.bd-main-menu-border-five ul li a {
  color: var(--clr-body-heading);
}
.bd-main-menu-border-four ul li.menu-item-has-children a:after,
.bd-main-menu-border-five ul li.menu-item-has-children a:after {
  color: var(--clr-body-heading);
}
.bd-main-menu-border-four ul li.menu-item-has-children:hover a:after,
.bd-main-menu-border-five ul li.menu-item-has-children:hover a:after {
  color: var(--clr-theme-1);
}

.bd-header-right-four .bd-header-social a {
  color: var(--clr-body-heading);
}
.bd-header-right-four .bd-header-social a:hover {
  color: var(--clr-theme-1);
}

.side-menu-icon-four i {
  color: var(--clr-body-heading);
}

.bd-main-menu-border-five {
  margin-left: -50px;
}
@media only screen and (min-width: 1400px) and (max-width: 1599px) {
  .bd-main-menu-border-five {
    margin-left: -30px;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .bd-main-menu-border-five {
    margin-left: -20px;
  }
}

.bd-header-input-search {
  margin-right: 20px;
}
@media (max-width: 767px) {
  .bd-header-input-search {
    display: none;
  }
}
.bd-header-input-search input {
  height: 55px;
  width: 295px;
  border: 1px solid var(--clr-bg-gray2);
  border-radius: 5px;
  outline: none;
  background: none;
  padding-left: 20px;
  padding-right: 55px;
  display: block;
  font-size: 14px;
  color: var(--clr-body-text);
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .bd-header-input-search input {
    width: 240px;
  }
}
.bd-header-input-search input::-moz-placeholder {
  font-size: 14px;
  opacity: 1;
  color: var(--clr-body-text);
}
.bd-header-input-search input::placeholder {
  font-size: 14px;
  opacity: 1;
  color: var(--clr-body-text);
}
.bd-header-input-search button {
  position: absolute;
  height: 55px;
  width: 50px;
  line-height: 55px;
  top: 0px;
  right: 0px;
  font-size: 14px;
  color: var(--clr-body-heading);
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
  background: none;
  border: none;
  outline: none;
}
.bd-header-input-search button:hover {
  color: var(--clr-common-white);
  background: var(--clr-theme-1);
}

@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .bd-header-input-search-six input {
    width: 240px;
  }
}

.bd-header-area-six {
  position: absolute;
  border-bottom: 1px solid var(--clr-bg-gray2);
}

.bd-header-contacts {
  display: flex;
  padding-left: 25px;
  border-left: 1px solid var(--clr-bg-gray2);
  margin-left: 5px;
}
@media only screen and (min-width: 768px) and (max-width: 991px),
  (max-width: 767px) {
  .bd-header-contacts {
    display: none;
  }
}
.bd-header-contacts-icon {
  margin-right: 15px;
}
.bd-header-contacts-icon i {
  font-size: 30px;
  display: inline-block;
  color: var(--clr-theme-1);
  line-height: 1;
}
.bd-header-contacts-text {
  text-align: left;
}
.bd-header-contacts-text span {
  font-size: 16px;
  display: block;
}
.bd-header-contacts-text a {
  font-size: 20px;
  font-weight: 700;
  color: var(--clr-body-heading);
}
@media only screen and (min-width: 1400px) and (max-width: 1599px),
  only screen and (min-width: 1200px) and (max-width: 1399px),
  only screen and (min-width: 992px) and (max-width: 1199px) {
  .bd-header-contacts-text a {
    font-size: 16px;
  }
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-header-input-search-six {
    margin-right: 0px;
  }
}
.bd-header-input-search-six input {
  border-radius: 30px;
}
.bd-header-input-search-six button {
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
}

.bd-header-right-breadcrumb .nav-search {
  color: var(--clr-body-heading);
}

.header-sidebar h5 {
  font-size: 22px;
  color: var(--clr-body-heading);
  font-weight: 600;
}

.header-sidebar-infos ul {
  text-align: center;
}
.header-sidebar-infos ul li {
  list-style: none;
  margin-bottom: 10px;
}
.header-sidebar-infos ul li a,
.header-sidebar-infos ul li span {
  font-size: 16px;
  color: var(--clr-body-text);
}

.sidebar__menu--social-two a {
  background: none;
  border: 1px solid var(--clr-body-text);
  color: var(--clr-body-text);
}
.sidebar__menu--social-two a:hover {
  background: var(--clr-theme-1);
  border-color: var(--clr-theme-1);
  color: var(--clr-common-white);
}

.custom-mar-10 {
  margin-left: -5px;
  margin-right: -5px;
}

.custom-pad-10 {
  padding-left: 5px;
  padding-right: 5px;
}

.side-info1 {
  background: black;
  width: 350px;
  height: 100%;
  position: fixed;
  z-index: 9999;
  right: -400px;
  top: 0;
  padding: 30px;
  transition: 0.6s;
  overflow-y: scroll;
}

.side-info1.info-open1 {
  right: 0;
}

.side-info-close1 {
  background: none;
  border: 0;
  color: #000;
  font-size: 20px;
  padding: 0;
  margin-bottom: 20px;
  right: 0;
}

.offcanvas-overlay1 {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: #000;
  height: 100%;
  width: 100%;
  z-index: 999;
  opacity: 0;
  visibility: hidden;
}

.offcanvas-overlay1.overlay-open1 {
  opacity: 0.5;
  visibility: visible;
}

.header-sidebar-instagram-item img {
  width: 100%;
}

.get-start2 {
  background-color: rgb(195, 41, 72);
  width: 120px;
  border: none;
  outline: none;
  box-shadow: none;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  border-radius: 8px;
  color: var(--clr-common-white);
  height: 50px;
}

.get-start {
  width: 120px;
  border: none;
  outline: none;
  box-shadow: none;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  text-transform: uppercase;
  border-radius: 8px;
  color: var(--clr-common-white);
  background-color: var(--clr-theme-1);
  height: 50px;
}

.get-start:before {
  background-color: var(--clr-common-white);
  content: "";
  height: 150px;
  left: -75px;
  position: absolute;
  top: -35px;
  transform: rotate(35deg);
  transition: all 1600ms cubic-bezier(0.19, 1, 0.22, 1);
  width: 60px;
  opacity: 0;
}

.get-start:hover:before {
  left: 120%;
  transition: all 1300ms cubic-bezier(0.19, 1, 0.22, 1);
  opacity: 0.35;
}
.bd-header-right .css-1nmdiq5-menu {
  width: 122px !important;
  text-align: Left !important;
}
/*
@media (min-width: 1291px) {
  .fix{
    display :none
  } */

/* .mobile-menu{
    display:none;
  }
}

@media (min-width:1290px) {
  .bd-main-menu ul li{
    position: relative;
  }

  .bd-main-menu .menu-item-has-children{
    position: relative;
    display: inline-block;


  } */

/* .get-start{
    position:relative;
    right:40px;
  } */

/* .mobile-menu li .bar-icon{
    width:45px;
    height:30px
  }

  .logo-navbar{
    padding: 25px 0px
  }

  .side-mobile{
    width:50px;
    height:100px;
    border:5px solid black
  }

  .side-mobile-open{
    position:relative;
    right:0px;
    background-color: black;
    width:40px;
    height:100px;
  } */

/* } */

/* .side-mobile{
  background: rgb(68, 24, 24);
  width: 400px;
  height: 100%;
  position: fixed;
  z-index: 9999;
  right: -400px;
  top: 0;
  padding: 30px;
  transition: 0.6s;
  overflow-y: scroll;

} */

/* @media (min-width: 992px){
  .col-lg-1{
    flex: 0 0 auto;
     width: 8.3333333333%;
  }
} */

.nav-icon {
  display: none;
}

@media screen and (max-width: 1199px) {
  .nav-icon {
    display: block;
    position: absolute;
    top: 0;
    right: 10px;
    font-size: 1.8rem;
    cursor: pointer;
    color: black;
  }

  .side-menu-icon {
    display: block;
    font-size: 1.5rem;
    cursor: pointer;
    color: black;
  }

  .bd-mobile-menu.mean-container {
    clear: both;
    overflow: hidden;
  }

  .bd-custom-container {
    padding-top: 20px;
  }

  .bd-main-menu ul li a {
    padding-top: 10px;
    padding-bottom: 10px;
  }

  /* .bd-header-area.active{
  padding-top: 20px;
} */

  .bd-header-btn {
    display: none;
  }

  #bd-mobile-menu {
    display: none;
  }
}

@media only screen and (min-width: 1200px) and (max-width: 1236px) {
  .bd-main-menu ul li a {
    font-size: 12px;
  }

  .btn_respon {
    padding-right: 18px;
  }
}

@media only screen and (min-width: 350px) and (max-width: 1100px) {
  .respon {
    width: 50%;
    text-align: left;
  }
}

@media only screen and (min-width: 350px) and (max-width: 1100px) {
  .respons2 {
    width: 40%;
  }
}
.bd-redirect a {
  color: black !important;
  font-size: 16px;
  font-weight: 500;
  display: inline-block;
  padding: 8px 12px;
  width: 100%;
}
.bd-redirect a:hover {
  background-color: #79309b;
  color: white !important;
  border-radius: 2px;
  cursor: pointer;
}
.bd-redirect a span {
  font-size: 22px;
}
.bd-redirect a:hover span {
  color: white !important;
}
.pb-direct-btn {
  position: absolute;
  right: 0%;
  top:22%;
  width: 500px !important;
}
