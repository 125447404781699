/* Portfolio */
/*----------------------------------------*/
@media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .bd-portfolio-spacing.pt-100 {
    padding-top: 105px;
  }
}

.bd-portfolio {
  overflow: hidden;
  position: relative;
  border-radius: 10px;
}
.bd-portfolio:before {
  position: absolute;
  height: 0%;
  width: 100%;
  content: "";
  background: linear-gradient(rgba(22, 22, 22, 0.06) 0%, #5c0385 100%);
  opacity: 0.85;
  left: 0px;
  bottom: 0px;
  z-index: 1;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.bd-portfolio:hover .bd-portfolio-text {
  bottom: 23px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), (max-width: 575px) {
  .bd-portfolio:hover .bd-portfolio-text {
    bottom: 13px;
  }
}
.bd-portfolio:hover:before {
  height: 100%;
}
.bd-portfolio img {
  width: 100%;
}
.bd-portfolio:hover img {
  transform: scale3d(1.1, 1.1, 1.1);
}

.bd-portfolio-text {
  position: absolute;
  left: 40px;
  bottom: -100px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  z-index: 2;
  right: 15px;
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
  .bd-portfolio-text {
    left: 25px;
  }
}
.bd-portfolio-text span {
  font-size: 14px;
  color: var(--clr-common-white);
  display: block;
  font-weight: 500;
}

.bd-portfolio-title {
  font-size: 20px;
  font-weight: 600;
  line-height: 1.5;
  color: var(--clr-common-white);
}

.bd-portfolio-three-menu button {
  font-size: 16px;
  font-weight: 500;
  border: 1px solid var(--clr-bg-gray2);
  height: 44px;
  border-radius: 10px;
  line-height: 42px;
  display: inline-block;
  padding: 0px 25px;
  outline: none;
  background: none;
  color: var(--clr-body-heading);
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
  margin-right: 20px;
  margin-bottom: 10px;
}
@media (max-width: 575px) {
  .bd-portfolio-three-menu button {
    padding: 0px 20px;
  }
}
.bd-portfolio-three-menu button:last-child {
  margin-right: 0px;
}
.bd-portfolio-three-menu button:hover, .bd-portfolio-three-menu button.active {
  background: var(--clr-theme-1);
  border-color: var(--clr-theme-1);
  color: var(--clr-common-white);
}

.bd-portfolio-three-btn a {
  font-size: 14px;
  font-weight: 600;
  color: var(--clr-body-heading);
  display: inline-block;
  text-decoration: underline;
  text-transform: uppercase;
}
.bd-portfolio-three-btn a:hover {
  text-decoration: none;
  color: var(--clr-theme-1);
}

.bd-portfolio-three h5 {
  margin-bottom: 0px;
}
.bd-portfolio-three p {
  font-size: 14px;
  color: var(--clr-common-white);
}

.bd-portfolio-active-two.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.bd-testimonial-four-active.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.bd-team-active.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
  display: inline-block;
  margin: 0px 5px;
  padding: 0px;
  display: inline-block;
  outline: none;
  cursor: pointer;
  font-size: 0px;
  opacity: 1;
  height: 6px;
  width: 20px;
  border-radius: 30px;
  border: none;
  background: var(--clr-border-4);
}
@media (max-width: 575px) {
  .bd-portfolio-active-two.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.bd-testimonial-four-active.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet,
.bd-team-active.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet {
    margin: 0px 4px;
  }
}
.bd-portfolio-active-two.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active,
.bd-testimonial-four-active.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active,
.bd-team-active.swiper-container-horizontal > .swiper-pagination-bullets .swiper-pagination-bullet.swiper-pagination-bullet-active {
  background: var(--clr-theme-1);
}

.bd-portfolio-active-two.swiper-container-horizontal .portfolio-dots,
.bd-portfolio-active-two.swiper-container-horizontal .team-dots,
.bd-team-active.swiper-container-horizontal .portfolio-dots,
.bd-team-active.swiper-container-horizontal .team-dots {
  position: absolute;
  bottom: 0px;
  left: 50%;
  transform: translateX(-50%);
}

.bd-testimonial-four-active.swiper-container-horizontal .testimonial-dots {
  position: absolute;
  bottom: 0px;
  left: calc(0% + 295px);
}
@media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px) {
  .bd-testimonial-four-active.swiper-container-horizontal .testimonial-dots {
    left: calc(0% + 185px);
  }
}
@media (max-width: 575px) {
  .bd-testimonial-four-active.swiper-container-horizontal .testimonial-dots {
    left: 0px;
  }
}

.bd-portfolio-item-five {
  position: relative;
  overflow: hidden;
}
.bd-portfolio-item-five-text {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.bd-portfolio-item-five-text a {
  height: 150px;
  width: 150px;
  border-radius: 50%;
  background: var(--clr-common-white);
  text-align: center;
  display: inline-block;
  font-size: 16px;
  color: var(--clr-body-heading);
  transform: scale(0);
  visibility: hidden;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
@media (max-width: 575px) {
  .bd-portfolio-item-five-text a {
    height: 120px;
    width: 120px;
  }
}
.bd-portfolio-item-five-text a i {
  display: block;
  color: var(--clr-theme-1);
  font-size: 24px;
  line-height: 1;
  margin-top: 50px;
}
@media (max-width: 575px) {
  .bd-portfolio-item-five-text a i {
    margin-top: 37px;
  }
}
.bd-portfolio-item-five:hover img {
  transform: scale3d(1.1, 1.1, 1.1);
}
.bd-portfolio-item-five:hover a {
  transform: scale(1);
  visibility: visible;
}

.bd-portfolio-active-five.swiper-container {
  margin-right: -305px;
}
@media (max-width: 575px) {
  .bd-portfolio-active-five.swiper-container {
    margin-right: 0px;
  }
}

.bd-portfolio-bg {
  position: absolute;
  top: 0px;
  left: 0px;
  background-repeat: no-repeat;
  background-position: top left;
  height: 890px;
  width: 100%;
}
