/* Slider */
/*----------------------------------------*/
.bd-slider-height {
  min-height: 800px;
}

@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-slider-height {
    min-height: 850px;
  }
}

@media (max-width: 767px) {
  .bd-slider-height {
    min-height: 700px;
  }
}


.bd-single-slider-overlay,
.bd-single-slider-overlay-invisible {
  position: relative;
}
.bd-single-slider-overlay:before,
.bd-single-slider-overlay-invisible:before {
  position: absolute;
  content: "";
  height: 100%;
  width: 100%;
  top: 0px;
  left: 0px;
  background: rgba(0, 0, 0, 0.7);
  /* z-index: 99; */
}

.bd-single-slider-overlay-invisible::before {
  background: transparent;
}

.cont-title{
justify-content: center;
}

.spacing{
  text-align: center;
  align-items: center;
  align-content: center;
  justify-content: center;
}

.bd-slider-title {
  font-size: 75px;
  color: #fdd796;
  line-height: 1.09;
  font-weight: 700;
  text-align: center;
  

}

@media (min-width: 1799px) and (max-width: 2300px) {
  .bd-slider-title {
    text-align: center;
    width:100%;
  }

  .cont-title{
    justify-content: center;
    }
}

@media (min-width: 1200px) and (max-width: 1799px) {
  .bd-slider-title {
    text-align: center;
    width:100%;
  }
  .cont-title{
    justify-content: center;
    }
}

@media (min-width: 992px) and (max-width: 1199px) {
  .bd-slider-title {
    font-size: 90px;
    text-align: center;
  }
  .cont-title{
    justify-content: center;
    }
}
@media (min-width: 768px) and (max-width: 991px) {
  .bd-slider-title {
    font-size: 80px;
    text-align: center;
  }
  .cont-title{
    justify-content: center;
    }
}
@media (min-width: 576px) and (max-width: 767px) {
  .bd-slider-title {
    font-size: 60px;
    text-align: center;
  }
  .cont-title{
    justify-content: center;
    }
}
@media (max-width: 575px) {
  .bd-slider-title {
    font-size: 40px;
    text-align: center;
  }
  .cont-title{
    justify-content: center;
    }
}
.bd-slider-title span {
  color: var(--clr-theme-1);
}

.bd-slider p {
  font-size: 20px;
  line-height: 1.66;
  color: #fdd796;
}
@media (max-width: 767px) {
  .bd-slider p {
    font-size: 16px;
  }
  .bd-slider p br {
    display: none;
  }
}

.bd-slide-bg {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;

  /* z-index: 0; */
  background-repeat: no-repeat;
  background-position: center center;
  background-size: cover;
  transform: scale(1);
  -webkit-transition: all 8s ease-out 0s;
  -moz-transition: all 8s ease-out 0s;
  -ms-transition: all 8s ease-out 0s;
  -o-transition: all 8s ease-out 0s;
  transition: all 8s ease-out 0s;
}

.swiper-slide-active .bd-slide-bg {
  -webkit-transform: scale(1.12);
  -moz-transform: scale(1.12);
  transform: scale(1.12);
}

/* @media (max-width: 575px) {
  br {
    display: none;
  }
} */

.bd-slide-shape {
  position: absolute;
  top: 0px;
  left: 0px;
  height: 100%;
}

.bd-slider-title-two {
  font-size: 90px;
  line-height: 1.11;
  font-weight: 700;
  color: var(--clr-common-white);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-slider-title-two {
    font-size: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-slider-title-two {
    font-size: 56px;
  }
}
@media (max-width: 767px) {
  .bd-slider-title-two {
    font-size: 48px;
  }
}
.bd-slider-title-two span {
  color: var(--clr-theme-1);
}

.bd-slider-subtitle-two {
  font-size: 24px;
  line-height: 1.21;
  font-weight: 700;
  color: var(--clr-common-white);
}
@media (max-width: 767px) {
  .bd-slider-subtitle-two {
    font-size: 20px;
  }
}

.bd-slider-title-three {
  color: var(--clr-body-heading);
}

.bd-slider-social-three h6 {
  font-size: 14px;
  font-weight: 500;
  color: var(--clr-body-heading);
  position: relative;
  line-height: 1;
  display: inline-block;
}
.bd-slider-social-three h6:before {
  position: absolute;
  content: "";
  height: 1px;
  background: var(--clr-body-heading);
  width: 80px;
  top: 8px;
  right: -86px;
}
.bd-slider-social-three ul li {
  display: inline-block;
  margin-right: 25px;
  list-style: none;
}
.bd-slider-social-three ul li:last-child {
  margin-right: 0px;
}
.bd-slider-social-three ul li a {
  font-size: 16px;
  color: var(--clr-body-heading);
}
.bd-slider-social-three ul li a:hover {
  color: var(--clr-theme-1);
}

.bd-slider-four p {
  font-size: 18px;
  color: var(--clr-common-white);
  line-height: 1.66;
}

.bd-slider-title-four {
  font-size: 90px;
  color: var(--clr-common-white);
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-slider-title-four {
    font-size: 60px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-slider-title-four {
    font-size: 56px;
  }
}
@media (max-width: 767px) {
  .bd-slider-title-four {
    font-size: 48px;
  }
}

.bd-header-signup {
  padding: 0px 30px;
  border-left: 1px solid var(--clr-bg-gray2);
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .bd-header-signup {
    border-left: 0px;
    padding-left: 0px;
  }
}
@media (max-width: 575px) {
  .bd-header-signup {
    display: none;
  }
}
.bd-header-signup a {
  font-size: 15px;
  font-weight: 500;
  color: var(--clr-body-heading);
}
.bd-header-signup a:hover {
  color: var(--clr-theme-1);
}

.bd-slider-five-hello-text {
  height: 40px;
  padding: 0px 15px;
  line-height: 40px;
  font-size: 24px;
  font-weight: 500;
  color: var(--clr-common-white) !important;
  border-radius: 5px;
  background: #FFBB01;
  display: inline-block;
  position: relative;
  /* z-index: 2; */
  margin-right: 22px;
}
.bd-slider-five-hello-text:after {
  position: absolute;
  content: "";
  height: 100%;
  width: 40%;
  left: 0px;
  top: 0px;
  background: #FF9429;
  /* z-index: -2; */
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
  -webkit-transition: all 0.3s ease-out 0s;
  -moz-transition: all 0.3s ease-out 0s;
  -ms-transition: all 0.3s ease-out 0s;
  -o-transition: all 0.3s ease-out 0s;
  transition: all 0.3s ease-out 0s;
}
.bd-slider-five-hello-text:before {
  position: absolute;
  content: "";
  width: 0;
  height: 0;
  border-style: solid;
  border-width: 0 21px 20px 0;
  border-color: transparent #FFBB01 transparent transparent;
  bottom: -20px;
  right: 15px;
}

.bd-slider-five-hello span {
  font-size: 24px;
  color: var(--clr-body-heading);
  display: inline-block;
  font-weight: 500;
}

@media (max-width: 767px) {
  .bd-slider-five br {
    display: none;
  }
}
.bd-slider-five .bd-slider-five-title {
  font-size: 120px;
  font-weight: 700;
  color: var(--clr-body-heading);
  line-height: 0.9;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bd-slider-five .bd-slider-five-title {
    font-size: 90px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-slider-five .bd-slider-five-title {
    font-size: 80px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .bd-slider-five .bd-slider-five-title {
    font-size: 60px;
  }
}
@media (max-width: 575px) {
  .bd-slider-five .bd-slider-five-title {
    font-size: 48px;
  }
}
.bd-slider-five .bd-slider-five-subtitle {
  font-size: 80px;
  font-weight: 700;
  color: var(--clr-body-heading);
  line-height: 1;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bd-slider-five .bd-slider-five-subtitle {
    font-size: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-slider-five .bd-slider-five-subtitle {
    font-size: 60px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .bd-slider-five .bd-slider-five-subtitle {
    font-size: 48px;
  }
}
@media (max-width: 575px) {
  .bd-slider-five .bd-slider-five-subtitle {
    font-size: 36px;
  }
}
.bd-slider-five .bd-slider-five-subtitle span {
  color: var(--clr-theme-1);
}
.bd-slider-five p {
  font-size: 20px;
  line-height: 1.6;
  color: var(--clr-body-text);
}
.bd-slider-five p b {
  font-weight: 600;
  color: var(--clr-body-heading);
}

.bd-slider-five-btn {
  display: inline-flex;
  margin-right: 45px;
}
@media (max-width: 575px) {
  .bd-slider-five-btn {
    margin-right: 10px;
  }
}
.bd-slider-five-btn .theme-btn {
  display: inline-block;
}
.bd-slider-five-btn .theme-btn i {
  margin-left: 8px;
  transform: translateY(4px);
  font-size: 18px;
}
@media (max-width: 575px) {
  .bd-slider-five-btn {
    margin-bottom: 10px;
  }
}

.bd-slider-five-video {
  display: inline-flex;
  align-items: center;
  text-align: left;
}
@media (max-width: 575px) {
  .bd-slider-five-video {
    margin-bottom: 10px;
  }
}
.bd-slider-five-video-icon {
  margin-right: 15px;
}
.bd-slider-five-video-icon a {
  height: 60px;
  width: 60px;
  color: var(--clr-theme-1);
  background: var(--clr-common-white);
  border-radius: 50%;
  display: inline-block;
  box-shadow: 0px 10px 8px rgba(3, 12, 29, 0.05);
  line-height: 62px;
  text-align: center;
}
.bd-slider-five-video-icon a:hover {
  background: var(--clr-theme-1);
  color: var(--clr-common-white);
}
.bd-slider-five-video-text span {
  font-size: 14px;
  display: inline-block;
  margin-bottom: 0px;
}
.bd-slider-five-video-text h5 {
  font-size: 16px;
  margin-bottom: 0px;
  font-weight: 500;
}
.bd-slider-five-video .play_btn {
  position: relative;
}

.bd-slider-five-social {
  position: absolute;
  width: 20px;
  top: 50%;
  transform: translateY(-50%);
  left: 100px;
}
@media only screen and (min-width: 1600px) and (max-width: 1750px) {
  .bd-slider-five-social {
    left: 50px;
  }
}
@media only screen and (min-width: 1400px) and (max-width: 1599px), only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
  .bd-slider-five-social {
    left: 15px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .bd-slider-five-social {
    display: none;
  }
}
.bd-slider-five-social span {
  font-size: 14px;
  font-weight: 500;
  line-height: 1;
  display: inline-block;
  color: var(--clr-body-heading);
  transform: rotate(-90deg);
  margin: 0px 0px 30px -14px;
}
.bd-slider-five-social ul {
  padding: 35px 0px;
  position: relative;
}
.bd-slider-five-social ul:after, .bd-slider-five-social ul:before {
  position: absolute;
  content: "";
  height: 30px;
  width: 1px;
  background: var(--clr-body-heading);
  top: 0px;
  left: 7px;
}
.bd-slider-five-social ul:before {
  top: auto;
  bottom: 0px;
}
.bd-slider-five-social ul li {
  list-style: none;
  padding: 5px 0px;
}
.bd-slider-five-social ul li a {
  font-size: 16px;
  color: var(--clr-body-heading);
}
.bd-slider-five-social ul li a:hover {
  color: var(--clr-theme-1);
}

.bd-slider-six-title {
  font-size: 78px;
  font-weight: 700;
  color: var(--clr-body-heading);
  line-height: 1.12;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .bd-slider-six-title {
    font-size: 76px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bd-slider-six-title {
    font-size: 70px;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-slider-six-title {
    font-size: 60px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .bd-slider-six-title {
    font-size: 48px;
  }
  .bd-slider-six-title br {
    display: none;
  }
}
@media (max-width: 575px) {
  .bd-slider-six-title {
    font-size: 36px;
  }
  .bd-slider-six-title br {
    display: none;
  }
}
.bd-slider-six-title span {
  color: var(--clr-theme-1);
}

.bd-slider-six-img {
  position: relative;
  /* z-index: 1; */
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
  .bd-slider-six-img {
    text-align: center;
    max-width: 500px;
    margin: 100px 0px;
    margin-left: auto;
    margin-right: auto;
  }
  .bd-slider-six-img img {
    margin-left: 0px;
  }
}
@media only screen and (min-width: 576px) and (max-width: 767px) {
  .bd-slider-six-img {
    text-align: center;
    margin: 100px 0px;
    margin-left: auto;
    margin-right: auto;
  }
  .bd-slider-six-img img.rounded-10 {
    margin-left: 0px;
    max-width: 400px;
  }
}
@media (max-width: 575px) {
  .bd-slider-six-img {
    text-align: center;
    margin: 20px 0px;
    margin-left: auto;
    margin-right: auto;
  }
  .bd-slider-six-img img.rounded-10 {
    margin-left: 0px;
    max-width: calc(100% - 30px);
  }
}
  .bd-slider-six-img img:first-child {
    width: 100%;
    height: 387px;
  }
.bd-slider-six-img .bd-slider-six-img-shape1,
.bd-slider-six-img .bd-slider-six-img-shape2,
.bd-slider-six-img .bd-slider-six-img-shape3,
.bd-slider-six-img .bd-slider-six-img-shape4 {
  position: absolute;
}
.bd-slider-six-img .bd-slider-six-img-shape1 {
  bottom: 55px;
  right: -105px;
  border-radius: 18px;
  animation-duration:8s;
  -webkit-animation: hero-thumb-animation2 4s linear infinite alternate;
  -moz-animation: hero-thumb-animation2 4s linear infinite alternate;
  -o-animation: hero-thumb-animation2 4s linear infinite alternate;
  animation: hero-thumb-animation2 4s linear infinite alternate;
}
@media (max-width: 575px) {
  .bd-slider-six-img .bd-slider-six-img-shape1 {
    max-height: 170px;
    bottom: 30px;
  }
}
.bd-slider-six-img .bd-slider-six-img-shape2 {
  left: -70px;
  bottom: -40px;
  /* z-index: -1; */
}
.bd-slider-six-img .bd-slider-six-img-shape3 {
  left: -40px;
  top: 150px;
  -webkit-animation: hero-dot-1 5s linear 0s infinite alternate;
  -moz-animation: hero-dot-1 5s linear 0s infinite alternate;
  -o-animation: hero-dot-1 5s linear 0s infinite alternate;
  animation: hero-dot-1 5s linear 0s infinite alternate;
}
.bd-slider-six-img .bd-slider-six-img-shape4 {
  right: -70px;
  top: 100px;
  -webkit-animation: hero-dot-2 5s linear 0s infinite alternate;
  -moz-animation: hero-dot-2 5s linear 0s infinite alternate;
  -o-animation: hero-dot-2 5s linear 0s infinite alternate;
  animation: hero-dot-2 5s linear 0s infinite alternate;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .bd-slider-six-img .bd-slider-six-img-shape4 {
    right: -70px;
    top: -70px;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .bd-slider-six-img .bd-slider-six-img-shape4 {
    right: -100px;
  }
}

.bd-slider-six-img-shape5 {
  position: absolute;
  right: 0px;
  bottom: 0px;
}

@media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .bd-slider-six {
    text-align: center;
  }
}

.bd-portfolio-active-seven {
  margin-left: -285px;
  margin-right: -285px;
}
@media (max-width: 767px) {
  .bd-portfolio-active-seven {
    margin: 0px;
  }
}

@keyframes dottedSquare {
  0% {
    transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -o-transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    -o-transform: rotate(360deg);
  }
}