/* Contact
/*----------------------------------------*/
.bd-contact-list {
    display: flex;
    align-items: center;
  }
  .bd-contact-list-icon {
    margin-right: 15px;
  }
  .bd-contact-list-icon i {
    height: 50px;
    width: 50px;
    border: 1px solid #F27CA4;
    line-height: 51px;
    text-align: center;
    font-size: 20px;
    color: var(--clr-theme-1);
    display: inline-block;
    border-radius: 50%;
  }
  .bd-contact-list-text a, .bd-contact-list-text span {
    font-size: 14px;
    display: inline-block;
  }
  .bd-contact-list-text a:hover {
    color: var(--clr-theme-1);
  }
  
  .bd-contact-list-text-title {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 3px;
  }
  
  .bd-contact-social-title {
    font-size: 24px;
    font-weight: 600;
  }
  
  .bd-contact-social a {
    font-size: 16px;
    display: inline-block;
    margin-right: 25px;
  }
  .bd-contact-social a:last-child {
    margin-right: 0px;
  }
  .bd-contact-social a:hover {
    color: var(--clr-theme-1);
  }
  
  .bd-contact-form form {
    background: var(--clr-bg-gray);
    padding: 30px 60px 40px 60px;
  }
  @media (max-width: 575px) {
    .bd-contact-form form {
      padding: 20px 30px 30px 30px;
    }
  }
  
  .bd-contact-field input,
  .bd-contact-field textarea {
    height: 60px;
    width: 100%;
    border-radius: 10px;
    background: var(--clr-common-white);
    border: none;
    font-size: 16px;
    font-weight: 400;
    padding: 0px 20px;
    outline: none;
    color: var(--clr-body-text);
  }
  .bd-contact-field input::-moz-placeholder,
  .bd-contact-field textarea::-moz-placeholder {
    font-size: 16px;
    opacity: 1;
    color: var(--clr-body-text);
  }
  .bd-contact-field input::placeholder,
  .bd-contact-field textarea::placeholder {
    font-size: 16px;
    opacity: 1;
    color: var(--clr-body-text);
  }
  .bd-contact-field textarea {
    height: 120px;
    padding-top: 10px;
    padding-bottom: 10px;
  }
  @media (max-width: 575px) {
    .bd-contact-field textarea {
      height: 200px;
    }
  }
  .bd-contact-field .theme-btn {
    font-size: 16px;
  }
  
  .bd-contact-form-title {
    font-size: 24px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .bd-contact .bd-section-title-wrapper p {
    padding-right: 110px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px) {
    .bd-contact .bd-section-title-wrapper p {
      padding-right: 50px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .bd-contact .bd-section-title-wrapper p {
      padding-right: 0px;
    }
  }
  
  .bd-contact-wrapper {
    margin-right: 50px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .bd-contact-wrapper {
      margin-right: 0px;
    }
  }
  .bd-contact-wrapper .bd-contct-item {
    display: flex;
    padding: 40px 40px 35px 40px;
    border-radius: 10px;
  }
  @media (max-width: 575px) {
    .bd-contact-wrapper .bd-contct-item {
      padding: 40px 20px 35px 20px;
    }
  }
  .bd-contact-wrapper .bd-contct-item-icon {
    margin-right: 20px;
  }
  .bd-contact-wrapper .bd-contct-item-icon i {
    font-size: 48px;
    line-height: 1;
    color: var(--clr-theme-1);
  }
  .bd-contact-wrapper .bd-contct-item-text a {
    font-size: 16px;
    color: var(--clr-body-text);
    display: block;
  }
  .bd-contact-wrapper .bd-contct-item-text a.mbb-5 {
    margin-bottom: 5px;
  }
  .bd-contact-wrapper .bd-contct-item-text a:hover {
    color: var(--clr-theme-1);
  }
  .bd-contact-wrapper .bd-contct-item-text span {
    font-size: 16px;
    color: var(--clr-body-text);
    display: block;
  }
  .bd-contact-wrapper .bd-contct-item-text-title {
    font-size: 18px;
    font-weight: 600;
    color: var(--clr-body-heading);
    margin-bottom: 5px;
  }
  
  .bd-contact-form-wrapper .bd-section-title {
    transform: translateY(-10px);
  }
  .bd-contact-form-wrapper .bd-contact-field input, .bd-contact-form-wrapper .bd-contact-field textarea {
    background: var(--clr-bg-gray);
  }
  
  .bd-map iframe {
    height: 500px;
    width: 100%;
  }

  .contact-form{
    width: 300px;
  }

  .bd-page-title-bg_contact {
    position: relative;
    background-color: var(--clr-bg-gray);
  }

  .bd-page-title-bg_contact:before {
    position: absolute;
    content:"";
    width: 100%;
    height: 100%;
    top: 0px;
    left: 0px;
    background: linear-gradient(to bottom, rgba(96, 8, 248, 0.2) 0%, rgba(96, 8, 248, 0.45) 100%);
  
    /* background: -moz-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: -webkit-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: linear-gradient(to right, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%); */
    filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#1aef0454", endColorstr="#1a6008f8",GradientType=1 );
  }

 /* iframe{
  margin-top: -60px;
 } */
  
 .bd-map{
  overflow: hidden; 
 }

 @media only screen and (min-width:350px) and (max-width: 786px){
  .contact-reponsive{
    flex-direction: column
  }
  .form-reponsive{
    width:100%
  }
  .email-form-responsive{
    width:100%
  }

 }

 @keyframes spinner {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.loading-spinner {
  width: 50px;
  height: 50px;
  border: 10px solid #f3f3f3; /* Light grey */
  border-top: 10px solid #383636; /* Black */
  border-radius: 50%;
  animation: spinner 1.5s linear infinite;
}