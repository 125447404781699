.about-us{
    font-size: 15px;
    line-height: 1px;
}

.bd-team-four span {
  font-size: 15px;
  color: var(--clr-common-white);
  display: inline-block;
  margin-bottom: 20px;
}
.bd-team-four .bd-team-four-text {
  position: absolute;
  left: 0px;
  right: 0px;
  margin: 0 auto;
  bottom: -100%;
  -webkit-transition: all 0.5s ease-out 0s;
  -moz-transition: all 0.5s ease-out 0s;
  -ms-transition: all 0.5s ease-out 0s;
  -o-transition: all 0.5s ease-out 0s;
  transition: all 0.5s ease-out 0s;
  text-align: center;
  z-index: 2;
}
.bd-team-four:hover .bd-team-four-text {
  bottom: 30px;
}

.bd-team-four-title {
  font-size: 20px;
  font-weight: 600;
  margin-bottom: 5px;
  color: var(--clr-common-white);
}

.bd-team-four-social ul li {
  display: inline-block;
  list-style: none;
  margin: 0px 5px;
}
.bd-team-four-social ul li a {
  height: 36px;
  width: 36px;
  line-height: 36px;
  display: inline-block;
  border-radius: 3px;
  font-size: 14px;
  color: var(--clr-common-white);
  text-align: center;
  background: rgba(22, 22, 22, 0.22);
}
.bd-team-four-social ul li a:hover {
  background: var(--clr-theme-1);
}

.slick-dots{
  position: relative;
  top:0px;
  left:0px;
}

.slick-prev {
  
  z-index: 1;
}
.slick-next {
 
  z-index: 1;
}

.about-avatar{
float: left;

}

.about-avatar img {
  border-radius: 50%;
}

.vertical-line{
  border-left: 2px solid var(--clr-body-text) ;
  height: 530px;
  float: left;
  position: relative;  
  right: 35px;
  top:70px
}

.bold_timeline_item_marker {
  left: auto;
  position: relative;
  width: 36px;
  right: 20px;
  border-color: #057485;
  background: #057485;
  top: 5rem;
}

.about-dot{
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
  position: relative;
  top:52px;
  right:12px;
}


.popover__content {
  position: absolute;
  top:30px;
  width:400px;
  left:15px;
  transform: translate(0, 10px);
  background-color: #eeeeee;


  box-shadow: 0 2px 10px 0 rgb(0,0,0,0.1);

}

/* .about-content-two{
  position: relative;

} */

.about-dot2{
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
  position: relative;
  left:62px;
  top:115px; 

}

.about-dot3{
  height: 20px;
  width: 20px;
  background-color: #bbb;
  border-radius: 50%;
  position: relative;
  left:61px;
  top:340px  

}


.popover__content2{
  position: absolute;
  top:-22px;
  width:400px;
  left:25px;
  transform: translate(0, 10px);
  background-color: #eeeeee;


  box-shadow: 0 2px 10px 0 rgb(0,0,0,0.1);

}
.section-img2{
  position: relative;
  top:190px;
  right:127px
}

.section-img3{
  position: relative;
  top:210px;
  right:228px
}

.section-img4{
  position: relative;
  top:355px;
  right:126px
}

.about-section4{
  position: relative;
  top:200px
}

.about-section5{
  position: relative;
  top:-50px
}

.about-section6{
  position: relative;
  top:400px
}

.section-text{
  position: relative;
  top:100px
}

.about-section7{
  position: relative;
  top:420px
}

/*----------------------------------------*/
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bd-skill-area .bd-section-title-wrapper p br {
    display: none;
  }
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bd-skill-area.pb-135 {
    padding-bottom: 115px;
  }
}

.bd-skill--title__wrapper {
  margin-bottom: 13px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.bd-skill--title__wrapper span {
  color: var(--clr-body-heading);
}

.bd-skill--title {
  font-size: 15px;
  display: inline-block;
  margin-bottom: 0px;
  font-weight: 500;
}

@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bd-skill__wrapper {
    margin-bottom: 20px;
  }
}
.bd-skill__wrapper .progress {
  height: 8px;
  border-radius: 10px;
  overflow: inherit;
  background-color: #e9ecef;
}
.bd-skill__wrapper .progress .progress-bar {
  background: var(--clr-theme-1);
  position: relative;
  overflow: inherit;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
}
.bd-skill__wrapper .progress .progress-bar span {
  height: 22px;
  width: 4px;
  display: inline-block;
  background: var(--clr-theme-1);
  position: absolute;
  transform: rotate(20deg);
  right: -2px;
  border-radius: 30px;
}

.bd-skill--content {
  overflow: hidden;
  padding-bottom: 15px;
}

.bd-skill--title__wrapper {
  margin-bottom: 8px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
}

.bd-skill--title__wrapper span {
  color: var(--clr-body-heading);
  position: absolute;
  top: 0;
}

.bd-skill--content-img {
  position: relative;
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .bd-skill--content-img {
    text-align: center;
    margin-bottom: 30px;
  }
}
.bd-skill--content-img-expe {
  position: absolute;
  left: 42%;
  transform: translateX(-50%);
  bottom: 70px;
  text-align: center;
}
@media only screen and (min-width: 1200px) and (max-width: 1399px) {
  .bd-skill--content-img-expe {
    left: 48%;
  }
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .bd-skill--content-img-expe {
    bottom: 40px;
    left: 48%;
  }
}
@media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
  .bd-skill--content-img-expe {
    left: 50%;
  }
}
@media (max-width: 575px) {
  .bd-skill--content-img-expe {
    bottom: 25px;
  }
}
.bd-skill--content-img-expe-title {
  font-size: 150px;
  font-weight: 700;
  color: var(--clr-body-heading);
  line-height: 1;
}
@media (max-width: 575px) {
  .bd-skill--content-img-expe-title {
    font-size: 80px;
  }
}
.bd-skill--content-img-expe span {
  font-size: 28px;
  font-weight: 600;
  color: var(--clr-body-heading);
  line-height: 1;
}
@media (max-width: 767px) {
  .bd-skill--content-img-expe span {
    font-size: 24px;
  }
}


@media (min-width:1400px) {
  .last-section{
    
    position: relative;
    top: 220px;
    right: 230px;
  }
  .section-img4{
    position: relative;
    top: 400px;
    right: 335px;
  }
}

@media(max-width:700px) {
  /* .justify-content-center{
    flex-direction: column;
  } */

  .second-part{
    margin-bottom: 50px
  }
}

.bd-page-title-bg_about {
  position: relative;
  background-color: var(--clr-bg-gray);
}
.bd-page-title-bg_about:before {
  position: absolute;
  content:"";
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: linear-gradient(to bottom, rgba(96, 8, 248, 0.2) 0%, rgba(96, 8, 248, 0.45) 100%);
  /* background: -moz-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
  background: -webkit-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
  background: linear-gradient(to right, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%); */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#1aef0454", endColorstr="#1a6008f8",GradientType=1 );
}
.box_type{
  width: 570px;
  /* border: 1px solid black; */
  background-color:#f7d557
}

.box_setup{
  padding:50px 30px 
  
}

.popup_vid{
  position: relative;
  bottom:340px;
  left:calc(18% + 20px);
  z-index: 3;
  /* width:100px;
  height:100px */
  width:70px
  
}

@media only screen and (min-width: 1400px) and (max-width: 1450px) {
.popup_vid{
  left:calc(19% + 20px);
}
}

@media only screen and (min-width: 1451px) and (max-width: 1550px) {
  .popup_vid{

    left:calc(21% + 20px);
  }
  }

  @media only screen and (min-width: 1551px) and (max-width: 1600px) {
    .popup_vid{
      left:calc(21% + 40px);
    }
  }

  @media only screen and (min-width: 1601px) and (max-width: 1660px) {
    .popup_vid{
      left:calc(22% + 40px);
    }
  }

  @media only screen and (min-width: 1661px) and (max-width: 1700px) {
    .popup_vid{

      left:calc(23% + 40px);
    }
  }

  @media only screen and (min-width: 1701px) and (max-width: 1760px) {
    .popup_vid{

      left:calc(24% + 40px);
    }
  }

  @media only screen and (min-width: 1761px) and (max-width: 1800px) {
    .popup_vid{
      left:calc(24% + 50px);
    }
  }
  

.bd-popup-video{
  text-decoration: none;
  color: var(--clr-theme-1);
  height: 60px;
width: 60px;
color: var(--clr-theme-1);
background: var(--clr-common-white);
border-radius: 50%;
display: inline-block;
box-shadow: 0px 10px 8px rgba(3, 12, 29, 0.05);
line-height: 62px;
text-align: center;
}

.bd-popup-video:hover{
  color:var(--clr-theme-1)
}

.container_new{
  display:flex;
  height: 50%;
}
.container_new2{
  display:flex;
  height: 50%;
}

.box_flip{
  transform-style: 3d;
  perspective: 1000px;
}

.box_flip img {
  position: absolute;
  border-radius: 10px;
  background-size: cover;
  backface-visibility: hidden;
  transition: 0.8s;
  box-shadow: rgba(50, 50, 93, 0.25) 0px 50px 100px -20px,
    rgba(0, 0, 0, 0.3) 0px 30px 60px -30px,
    rgba(10, 37, 64, 0.35) 0px -2px 6px 0px inset;
}

.box_flip img:nth-child(2) {
  transform: rotateY(180deg);
}

.box_flip:hover img:nth-child(2) {
  transform: rotateY(0deg);
}

.box_flip:hover img:nth-child(1) {
  transform: rotateY(-180deg);
}

.about-second-resp{
  width:100%;
  margin-left:30px;
}
.responsive-section1{
  width:66%;
}

.responsive-section2{
  width:34%;
}

.third-section1-resp{
  width:45%;
}
.third-section2-resp{
  width:55%;
}
.third-section-tittle{
  font-size:46px
}
.fourth-about-section1{
  width:40%;
}
.fourth-about-section2{
  width:60%;
}
.fifth-about-section1{
  width:40%
}
.fifth-about-section2{
  width:60%
}
.fifth-about-tittle{
  font-size:46px;
}
.team-responsive{
  margin-right:30px;
}

@media only screen and (min-width:350px) and (max-width:991px){ 
  .about-second-resp{
    margin-left:0px
  }
  .responsive-section1{
    width:100%
  }
  .para-about{
    margin-left:0px
  }
  .responsive-section2{
    width:100%
  }
  .about-avatar{
    margin-left:0px;
  }
  .about-dot2{
    left:62px;
  }
  .section-img2{
    right:125px;
  }
  .responsive-section-img{
    position:absolute;
    top: 225px;
    left: 37px;
  }
  .popover__content1{
    width:270px;
   }
  
   .para-text{
    font-size: 16px
   }
   .second-part-resp{
    padding-bottom: 150px;
   }
   .third-section1-resp{
    width:100%
   }
   .third-section2-resp{
    width:100%
   }
   .third-section-tittle{
    font-size:36px;
    margin-left: 0px;
   }
   .third-para{
    margin-left:0px;
   }
   .fourth-about-section1{
    width:100%;
   }
   .fourth-about-section2{
    width:100%;
    height:312px
   }
   .container_new{
    margin-bottom:0px
   }
   .fifth-about-section1{
    width:100%;
   }
   .fifth-about-section2{
    width:100%;
    padding-left:15px
   }
    .fifth-about-tittle{
      font-size:36px;
   }

  
.popup_vid {
    position: relative;
    bottom: 1365px;
    left: 142px;
    z-index: 3;
  }
   
   .bd-popup-video{
    width:50px;
    height:50px;  
    line-height:52px
   }
   
} 

@media only screen and (min-width:350px) and (max-width:510px){ 
  .popover__content{
    width:235px;
   }
    .popover__content2{
     width:235px;
    }
}

/* @media only screen and (min-width:350px) and (max-width:360px){
  .popup_vid {
    position: relative;
    bottom: 1365px;
    left: 142px;
    z-index: 3;
  }
}
@media only screen and (min-width:361px) and (max-width:380px){
  .popup_vid {
    position: relative;
    bottom: 1330px;
    left: 150px;
    z-index: 3;
  }
}
@media only screen and (min-width:381px) and (max-width:385px){
  .popup_vid {
    position: relative;
    bottom: 1330px;
    left: 150px;
    z-index: 3;
  }
} */


@media only screen and (min-width:350px) and (max-width:550px){
  .team-responsive{
    margin-right: 0px;
   }
}

@media (min-width:1400px){
    .responsive-section2{
      width:37%
    }
    .responsive-section1{
      width:56%;
    }
}

.first-responsive{
  height:385px
}

@media only screen and (min-width:350px) and (max-width: 991px){
  
  .about-dot3{
    position: relative;
    left: 60px;
    top: 340px;
  }
}

.about-avatar{
  margin-left: 35px;
}

@media only screen and (min-width:350px) and (max-width: 360px){
  .section-img4{
    position: relative;
    top: 370px;
  }
}

@media only screen and (min-width:361px) and (max-width: 991px){
  .section-img4{
    position: relative;
    top: 400px;
    right: 230px;
  }
}