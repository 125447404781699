/* Footer
/*----------------------------------------*/
.bd-footer-info-logo a:before {
    display: none;
  }
  
  .bd-footer-info-item h6 {
    font-size: 16px;
    font-weight: 400;
    color: var(--clr-common-white);
    margin-bottom: 5px;
  }
  .bd-footer-info-item a, .bd-footer-info-item span {
    font-size: 16px;
    font-weight: 400;
    color: var(--clr-body-text2);
    display: inline-block;
  }
  
  .bd-footer-widget ul li {
    list-style: none;
    margin-bottom: 10px;
  }
  .bd-footer-widget ul li:last-child {
    margin-bottom: 0px;
  }
  .bd-footer-widget ul li a {
    font-size: 16px;
    font-weight: 400;
    color: var(--clr-body-text2);
    display: inline-block;
  }
  .bd-footer-widget a {
    position: relative;
  }
  .bd-footer-widget a:hover {
    color: var(--clr-common-white);
  }
  
  .bd-footer-widget-title {
    font-size: 18px;
    font-weight: 600;
    color: var(--clr-common-white);
  }
  
  .bd-footer-newsletter form input {
    height: 60px;
    width: 100%;
    background: var(--clr-body-heading2);
    border-radius: 4px;
    padding: 0px 40px 0px 20px;
    color: var(--clr-body-text2);
    font-size: 15px;
    border: 0px;
    outline: none;
  }
  .bd-footer-newsletter form button {
    width: 100%;
    justify-content: center;
  }
  .bd-footer-newsletter form button:hover {
    color: var(--clr-common-white);
  }
  
  .bd-footer-newsletter-field-btn button {
    display: flex;
    align-items: center;
  }
  .bd-footer-newsletter-field-btn button i {
    margin-left: 8px;
  }
  
  .bd-copyright-area {
    border-top: 1px solid var(--clr-border-3);
  }
  
  .bd-copyright {
    padding-top: 15px;
    padding-bottom: 17px;
  }
  .bd-copyright a {
    font-weight: 600;
    color: var(--clr-common-white);
  }
  .bd-copyright p {
    color: var(--clr-body-text2);
  }
  .bd-copyright p a:hover {
    color: var(--clr-theme-1);
  }
  
  .footer-col-2 {
    padding-left: 75px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-col-2 {
      padding-left: 50px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .footer-col-2 {
      padding-left: 0px;
    }
  }
  
  .footer-col-3 {
    padding-left: 25px;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .footer-col-3 {
      padding-left: 0px;
    }
  }
  
  .bd-footer-copyright-two span {
    font-size: 16px;
    line-height: 26px;
    display: inline-block;
    color: var(--clr-body-text2);
  }
  .bd-footer-copyright-two span a {
    font-weight: 600;
    color: var(--clr-common-white);
  }
  .bd-footer-copyright-two span a:hover {
    color: var(--clr-theme-1);
  }
  
  .bd-footer-widget-two .bd-footer-info {
    text-align: right;
  }
  .bd-footer-widget-two .bd-footer-info-item {
    display: inline-block;
    text-align: left;
  }
  .bd-footer-widget-two .bd-footer-info-item:first-child {
    margin-right: 50px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .bd-footer-widget-two .bd-footer-info-item:first-child {
      margin-right: 24px;
    }
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .bd-footer-logo-two.text-center {
      text-align: end !important;
    }
  }
  
  @media (max-width: 767px) {
    .bd-footer-widget-two {
      text-align: center !important;
    }
  }
  
  @media (max-width: 767px) {
    .bd-footer-widget-two .bd-footer-info {
      text-align: center !important;
    }
  }
  @media (max-width: 575px) {
    .bd-footer-widget-two .bd-footer-info {
      text-align: center !important;
    }
    .bd-footer-widget-two .bd-footer-info .bd-footer-info-item {
      margin-right: 0px;
      text-align: center;
    }
  }
  
  .bd-footer-logo-two a:before {
    display: none;
  }
  
  .bd-footer-widget-four {
    position: relative;
    padding-top: 100px;
    padding-bottom: 85px;
    overflow: hidden;
  }
  .bd-footer-widget-four-bg {
    position: absolute;
    bottom: -790px;
    left: 0px;
    height: 1130px;
    width: 100%;
    background: var(--clr-bg-gray);
    border-radius: 50%;
  }
  @media (max-width: 767px) {
    .bd-footer-widget-four-bg {
      border-radius: 0px;
    }
  }
  @media (max-width: 575px) {
    .bd-footer-widget-four-bg {
      bottom: -770px;
    }
  }
  .bd-footer-widget-four-social ul li {
    list-style: none;
    display: inline-block;
    margin: 0px 7px;
  }
  .bd-footer-widget-four-social ul li a {
    height: 40px;
    width: 40px;
    line-height: 38px;
    text-align: center;
    border: 1px solid var(--clr-border-5);
    font-size: 14px;
    color: var(--clr-body-heading);
    display: inline-block;
    border-radius: 50%;
    overflow: hidden;
  }
  .bd-footer-widget-four-social ul li a:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    transform: scale(0);
    background: var(--clr-theme-1);
    z-index: -1;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    border-radius: 50%;
  }
  .bd-footer-widget-four-social ul li a::before {
    display: none;
  }
  .bd-footer-widget-four-social ul li a:hover {
    border-color: var(--clr-theme-1);
  }
  .bd-footer-widget-four-social ul li a:hover:after {
    transform: scale(1);
  }
  @media (max-width: 575px) {
    .bd-footer-widget-four-copyright {
      padding: 0px 10px;
    }
  }
  .bd-footer-widget-four-copyright span {
    font-size: 16px;
    color: var(--clr-body-text3);
    display: inline-block;
  }
  .bd-footer-widget-four-copyright span a {
    font-weight: 600;
    color: var(--clr-body-heading);
  }
  .bd-footer-widget-four-copyright span a:hover {
    color: var(--clr-theme-1);
  }
  .bd-footer-widget-four-copyright span a::before {
    display: none;
  }
  .bd-footer-widget-four-privacy ul li {
    list-style: none;
    display: inline-block;
  }
  .bd-footer-widget-four-privacy ul li a {
    font-size: 16px;
    font-weight: 500;
    display: inline-block;
    color: var(--clr-body-heading);
    padding: 0px 25px;
    border-right: 1px solid var(--clr-border-5);
    line-height: 1;
  }
  @media (max-width: 575px) {
    .bd-footer-widget-four-privacy ul li a {
      padding: 0px 10px;
    }
  }
  .bd-footer-widget-four-privacy ul li a::before {
    display: none;
  }
  .bd-footer-widget-four-privacy ul li a:hover {
    color: var(--clr-theme-1);
  }
  .bd-footer-widget-four-privacy ul li:last-child a {
    border-right: 0px;
  }
  
  .bd-footer-widget-title-six {
    color: var(--clr-body-heading);
  }
  
  .bd-footer-info-item-six h6 {
    color: var(--clr-body-heading);
  }
  .bd-footer-info-item-six a, .bd-footer-info-item-six span {
    color: var(--clr-body-text);
  }
  
  .bd-footer-widget-six a:hover {
    color: var(--clr-theme-1);
  }
  .bd-footer-widget-six a:hover::before {
    background: var(--clr-body-heading);
  }
  .bd-footer-widget-six p {
    font-size: 16px;
    color: var(--clr-body-text);
    line-height: 28px;
  }
  
  .bd-copyright-area-six {
    padding-top: 17px;
    border-top: 1px solid var(--clr-border-6);
    padding-bottom: 5px;
  }
  
  .bd-copyright-six p {
    font-size: 16px;
    color: var(--clr-body-text);
    display: inline-block;
    margin-bottom: 0px;
  }
  .bd-copyright-six p a {
    font-weight: 600;
    color: var(--clr-body-text);
  }
  .bd-copyright-six p a:hover {
    color: var(--clr-theme-1);
  }
  .bd-copyright-six ul li {
    list-style: none;
    display: inline-block;
    padding-right: 20px;
    margin-right: 18px;
    border-right: 1px solid var(--clr-border-6);
  }
  .bd-copyright-six ul li:last-child {
    padding-right: 0px;
    margin-right: 0px;
    border-right: 0px;
  }
  .bd-copyright-six ul li a {
    font-size: 16px;
    color: var(--clr-body-heading);
    line-height: 1;
  }
  .bd-copyright-six ul li a:hover {
    color: var(--clr-theme-1);
  }
  
  .footer-col-6 {
    padding-left: 65px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .footer-col-6 {
      padding-left: 40px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .footer-col-6 {
      padding-left: 0px;
    }
  }
  
  .footer-col-8 {
    padding-left: 35px;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .footer-col-8 {
      padding-left: 0px;
    }
  }
  
  .bd-footer-widget-six-social a {
    height: 40px;
    width: 40px;
    line-height: 40px;
    text-align: center;
    border: 1px solid var(--clr-border-6);
    font-size: 16px;
    color: var(--clr-body-heading);
    display: inline-block;
    margin-right: 15px;
    border-radius: 50%;
    overflow: hidden;
    z-index: 1;
    position: relative;
  }
  .bd-footer-widget-six-social a:last-child {
    margin-right: 0px;
  }
  .bd-footer-widget-six-social a:after {
    position: absolute;
    content: "";
    height: 100%;
    width: 100%;
    top: 0px;
    left: 0px;
    transform: scale(0);
    background: var(--clr-theme-1);
    z-index: -1;
    -webkit-transition: all 0.3s ease-out 0s;
    -moz-transition: all 0.3s ease-out 0s;
    -ms-transition: all 0.3s ease-out 0s;
    -o-transition: all 0.3s ease-out 0s;
    transition: all 0.3s ease-out 0s;
    border-radius: 50%;
  }
  .bd-footer-widget-six-social a::before {
    display: none;
  }
  .bd-footer-widget-six-social a:hover {
    border-color: var(--clr-theme-1);
    color: var(--clr-common-white);
  }
  .bd-footer-widget-six-social a:hover:after {
    transform: scale(1);
  }
  
  .bd-copyright-area-seven {
    padding-top: 32px;
    padding-bottom: 20px;
  }
  
  .bd-error-area {
    padding-top: 325px;
    padding-bottom: 250px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px) {
    .bd-error-area {
      padding-top: 300px;
    }
  }
  @media (max-width: 767px) {
    .bd-error-area {
      padding-top: 300px;
      padding-bottom: 200px;
    }
  }
  
  .bd-error h1 {
    font-size: 250px;
    font-weight: 700;
    color: var(--clr-theme-1);
    line-height: 1;
    margin-bottom: 0px;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .bd-error h1 {
      font-size: 200px;
    }
  }
  @media (max-width: 767px) {
    .bd-error h1 {
      font-size: 120px;
    }
  }
  .bd-error h1 span {
    color: var(--clr-body-heading);
  }
  .bd-error h3 {
    font-size: 36px;
    font-weight: 600;
    color: var(--clr-body-heading);
  }
  .bd-error p {
    font-size: 16px;
    line-height: 26px;
    color: var(--clr-body-text);
    margin-bottom: 33px;
  }
  @media (max-width: 767px) {
    .bd-error p br {
      display: none;
    }
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .bd-footer-newsletter-field-btn .theme-btn {
      padding: 0px 20px;
    }
  }

  .footer-column{
    flex-wrap: wrap;
  }

  @media (min-width:991px) {
    .bd-footer-widget{
      /* margin-left: 0px; */
    }
  }

  .column-first{
    width:20%
  }
  .column-second{
    width:20%
  }
  .column-third{
    width:20%
  }
  .column-fourth{
    width:20%
  }
  .column-fifth{
    width:20%
  }


  .bd-footer-widget{
    margin-bottom: 60px;
  }



  @media only screen and (min-width: 350px) and (max-width: 575px) {
    .footer-column{
      flex-direction: column;
    }
    .column-first{
      width:100%
    }

    .column-second{
      width:100%
    }
    .column-third{
      width:100%
    }
    .column-fourth{
      width:100%
    }
    .column-fifth{
      width:100%
    }

  }

  @media only screen and (min-width: 576px) and (max-width: 760px){
    .column-first{
      width:50%
    }
    .column-second{
      width:50%
    }
    .column-third{
      width:50%
    }
    .column-fourth{
      width:50%
    }
    .column-fifth{
      width:50%
    }
  }

  @media only screen and (min-width: 761px) and (max-width: 900px){
    .column-first{
      width:33.33%
    }
    .column-second{
      width:33.33%
    }
    .column-third{
      width:33.33%
    }
    .column-fourth{
      width:33.33%
    }
    .column-fifth{
      width:33.33%
    }

  }

  @media only screen and (min-width: 901px) and (max-width: 1120px){
    .column-first{
      width:25%
    }
    .column-second{
      width:25%
    }
    .column-third{
      width:25%
    }
    .column-fourth{
      width:25%
    }
    .column-fifth{
      width:25%
    }
  }
  .address{
    color:var(--clr-body-text2);
  }


 

  