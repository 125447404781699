.site-link{
    font-size: 25px;
    margin-top:20px;
    font-weight: 500;
    color:var(--clr-body-heading);
    width:50%;
}

.site-link:hover{
    color:rgb(195, 41, 72)
}

.site-link:hover i{
    animation-name: wobble-vertical;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 3;
}