.modal,
.overlay {
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  position: fixed;
}

.overlay {
  background: rgba(49, 49, 49, 0.8);
}
.modal-content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  line-height: 1.4;
  background: #f1f1f1;
  padding: 14px 28px;
  border-radius: 3px;
  max-width: 600px;
  min-width: 300px;
}

.close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  padding: 5px 7px;
}

.page-numbers.active {
  background-color: var(--clr-body-heading);
  color: white;
}

/* .page-numbers:focus{
    background-color: var(--clr-body-heading);
    color:white
} */

.spinner {
  position: fixed;
  z-index: 99999;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgb(0, 0, 0);
  background-color: rgba(0, 0, 0, 0.4);
}
.bd-current-open-form label {
  font-size: 15px;
  margin-bottom: 10px;
  margin-left: 8px;
  font-weight: 500;
}
.bd-current-open-form input {
  width: 100%;
  border-radius: 10px;
  background: var(--clr-common-white);
  border: none;
  font-size: 14.5px;
  font-weight: 400;
  padding: 12px 20px;
  border-radius: 5px;
  outline: none;
  color: var(--clr-body-text);
}
.bd-current-open-form input::placeholder {
  font-size: 13.5px !important;
  color: black;
  opacity: 0.6;
}
.bd-current-open-form select {
  width: 100%;
  height: 46px;
  background-color: white;
  border: medium none;
  border-radius: 5px;
  color: var(--clr-body-text);
  font-weight: 400;
  font-size: 14px;
  padding-left: 20px;
}
.current-modal .modal-dialog {
  max-width: 1000px !important;
  z-index: 9999;
  /* height: 100%; */
}
.current-modal .modal-content {
  max-width: 1000px !important;
  z-index: 9999;
}
.display-none {
  display: none;
}
.css-13cymwt-control {
  border: none !important;
}
.bd-header-right .css-1u9des2-indicatorSeparator {
  display: none !important;
}
.css-1nmdiq5-menu {
  opacity: 1 !important;
  color: var(--clr-body-text) !important;
}
.form-type {
  padding: 12px 16px;
  text-align: center;
  background-color: #c32948;
  color: white;
  border-radius: 5px;
}
.smiley-face {
  font-size: 25px;
  margin-bottom: 0px;
}
.purple {
  border-color: #5c0385 !important;
}
.orange {
  border-color: orange !important;
}
.red {
  border-color: red !important;
}
.text-right {
  text-align: right;
}
.font-15 {
  font-size: 15.5px;
}
.pl-10 {
  padding-left: 10px !important;
}
.text-yellow {
  color: #ffbf51;
}
.col-cus-2 {
  width: 17%;
}
.col-16 {
  width: 16%;
}
.p-0 {
  padding: 0px;
}
.col-24 {
  width: 24%;
}
.bd-position-popup .swal2-popup {
  width: 630px;
}
.bd-position-popup .swal2-html-container {
  font-size: 17px;
}
.bd-position-popup .swal2-confirm {
  background-color: rgb(253, 215, 150);
  color: #5c0385;
  text-align: center;
}
.bd-position-popup .swal2-footer {
  text-align: center;
}
.bd-position-popup .swal2-title {
  font-size: 20px;
}
@media screen and (min-width: 320px) and (max-width: 600px) {
  .resp-row {
    flex-wrap: wrap;
    flex-direction: column;
  }
  .resp-w-100 {
    width: 100% !important;
  }
  .current-modal .modal-content {
    top: 100%;
  }
  /* .modal-2 .modal-content {
    top: 50%;
  } */
  .current-modal .modal-header {
    padding: 0px;
  }
}
