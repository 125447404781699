.bd-about-img {
    position: relative;
    z-index: 1;
  }
  .bd-about-img .bd-about-seconday-img {
    position: absolute;
    top: 60px;
    right: 30px;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .bd-about-img .bd-about-seconday-img {
      right: 0px;
    }
  }
  @media (max-width: 575px) {
    .bd-about-img .bd-about-seconday-img {
      display: none;
    }
  }
  
  .bd-about-shape-box {
    position: relative;
  }
  .bd-about-shape-box .bd-about-shape {
    position: absolute;
  }
  .bd-about-shape-box .bd-about-shape-1 {
    bottom: 40px;
    left: -60px;
  }
  @media only screen and (min-width: 1400px) and (max-width: 1599px) {
    .bd-about-shape-box .bd-about-shape-1 {
      left: -220px;
    }
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px), only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .bd-about-shape-box .bd-about-shape-1 {
      display: none;
    }
  }
  .bd-about-shape-box .bd-about-shape-2 {
    top: 49%;
    left: 8.5%;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .bd-about-shape-box .bd-about-shape-2 {
      top: 25%;
      left: auto;
      right: 80px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .bd-about-shape-box .bd-about-shape-2 {
      display: none;
    }
  }
  .bd-about-shape-box .bd-about-shape-3 {
    margin-left: 20px;
    margin-top: -20px;
    -webkit-animation: hero-circle-1 6s linear 0s infinite alternate;
    -moz-animation: hero-circle-1 6s linear 0s infinite alternate;
    -o-animation: hero-circle-1 6s linear 0s infinite alternate;
    animation: hero-circle-1 6s linear 0s infinite alternate;
  }
  @media (max-width: 575px) {
    .bd-about-shape-box .bd-about-shape-3 {
      display: none;
    }
  }
  .bd-about-shape-box .bd-about-shape-4 {
    margin-left: 40px;
    margin-top: 12px;
    animation-duration: 2.5s;
    -webkit-animation: hero-thumb-animation 4s linear infinite alternate;
    -moz-animation: hero-thumb-animation 4s linear infinite alternate;
    -o-animation: hero-thumb-animation 4s linear infinite alternate;
    animation: hero-thumb-animation 4s linear infinite alternate;
  }
  .bd-about-shape-box .bd-about-shape-5 {
    bottom: -140px;
    left: 220px;
    animation-duration: 2.5s;
    animation-fill-mode: both;
    animation-iteration-count: infinite;
    animation-name: hero-bounce;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .bd-about-shape-box .bd-about-shape-5 {
      left: 130px;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .bd-about-shape-box .bd-about-shape-5 {
      left: 200px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .bd-about-shape-box .bd-about-shape-5 {
      display: none;
    }
  }
  .bd-about-shape-box .bd-about-shape-6 {
    bottom: -40px;
    left: -46px;
    z-index: -1;
    -webkit-animation: hero-dot-1 2s linear 0s infinite alternate;
    -moz-animation: hero-dot-1 2s linear 0s infinite alternate;
    -o-animation: hero-dot-1 2s linear 0s infinite alternate;
    animation: hero-dot-1 2s linear 0s infinite alternate;
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .bd-about-text.pt-95 {
      flex-direction: row;
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .bd-about-text.pt-95 {
      padding-top: 120px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .bd-about-text.pt-95 {
      padding-top: 115px;
    }
  }
  @media (max-width: 575px) {
    .bd-about-text.pt-95 {
      padding-top: 50px;
    }
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .md-pr-50 {
      padding-right: 50px;
    }
  }
  
  @media (max-width: 575px) {
    .xss-w-full {
      width: 100%;
    }
  }
  
  .bd-about-text-two ul li {
    font-size: 16px;
    font-weight: 500;
    color: var(--clr-body-heading);
    list-style: none;
    margin-bottom: 10px;
  }
  .bd-about-text-two ul li span, .bd-about-text-two ul li a {
    font-size: 16px;
    font-weight: 500;
    color: var(--clr-body-text);
    padding-left: 5px;
  }
  
  .bd-about-img-two {
    position: relative;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .bd-about-img-two img.pl-110 {
      padding-left: 100px;
    }
  }
  @media (max-width: 575px) {
    .bd-about-img-two img.pl-110 {
      padding-left: 0px;
      width: 100%;
    }
  }
  .bd-about-img-two .bd-about-img-two-expe {
    position: absolute;
    left: 0px;
    bottom: 155px;
    -webkit-animation: hero-dot-1 5s linear 0s infinite alternate;
    -moz-animation: hero-dot-1 5s linear 0s infinite alternate;
    -o-animation: hero-dot-1 5s linear 0s infinite alternate;
    animation: hero-dot-1 5s linear 0s infinite alternate;
  }
  @media (max-width: 575px) {
    .bd-about-img-two .bd-about-img-two-expe {
      display: none;
    }
  }
  .bd-about-img-two .bd-about-img-two-expe-text {
    position: absolute;
    right: 45px;
    bottom: 75px;
    text-align: center;
  }
  
  .bd-about-img-two-expe-text h4 {
    font-size: 50px;
    font-weight: 600;
    color: var(--clr-common-white);
    margin-bottom: 0px;
  }
  .bd-about-img-two-expe-text span {
    font-size: 16px;
    font-weight: 500;
    color: var(--clr-common-white);
    display: inline-block;
  }
  
  .rounded-10 {
    border-radius: 10px;
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .bd-about-four-bg {
      padding-bottom: 110px;
    }
  }
  
  .bd-about-service-four {
    display: flex;
  }
  .bd-about-service-four-icon {
    margin-right: 30px;
  }
  .bd-about-service-four-icon i {
    font-size: 48px;
    line-height: 1;
    display: inline-block;
    color: var(--clr-theme-1);
  }
  .bd-about-service-four-text h5 {
    font-size: 18px;
    font-weight: 600;
  }
  .bd-about-service-four-text p {
    font-size: 16px;
    line-height: 1.66;
    margin-bottom: 0px;
  }
  .bd-about-service-four:hover i {
    animation-name: wobble-vertical;
    animation-duration: 1s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: 3;
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .bd-about-text.bd-about-text-four.pt-35.mb-40 {
      padding-top: 125px;
      margin-bottom: 0px;
    }
  }
  @media (max-width: 575px) {
    .bd-about-text.bd-about-text-four.pt-35.mb-40 {
      padding-top: 65px;
    }
  }
  
  .bd-about-text-five-facts {
    padding-top: 20px;
    /* margin-top: 25px; */
  }
  .bd-about-text-five-facts ul li {
    display: inline-block;
    padding-right: 20px;
    margin-right: 20px;
    border-right: 1px solid var(--clr-bg-gray2);
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .bd-about-text-five-facts ul li {
      padding-right: 20px;
      margin-right: 25px;
    }
  }
  @media only screen and (min-width: 576px) and (max-width: 767px) {
    .bd-about-text-five-facts ul li {
      padding-right: 30px;
      margin-right: 20px;
    }
  }
  @media (max-width: 575px) {
    .bd-about-text-five-facts ul li {
      padding-right: 0px;
      margin-right: 0px;
      border-right: 0px;
      display: block;
    }
  }
  .bd-about-text-five-facts ul li:last-child {
    padding-right: 0px;
    margin-right: 0px;
    border-right: 0px;
  }
  
  .bd-about-text-five-fact .bd-slider-fact-title {
    font-size: 28px;
    font-weight: 700;
    color: var(--clr-body-heading);
    margin-bottom: 0px;
  }
  .bd-about-text-five-fact .bd-slider-fact-subtitle {
    font-size: 16px;
  }
  
  .text-heading {
    color: var(--clr-body-heading) !important;
  }
  
  .bd-about-img-five {
    position: relative;
  }
  .bd-about-img-five-expe {
    position: absolute;
    top: 170px;
    left: 0px;
    text-align: center;
    height: 160px;
    width: 160px;
    background: var(--clr-theme-1);
    border-radius: 50%;
    padding-top: 32px;
    animation-name: wobble-vertical;
    animation-duration: 2s;
    animation-timing-function: ease-in-out;
    animation-iteration-count: infinite;
  }
  .bd-about-img-five-expe h3 {
    font-size: 50px;
    margin-bottom: 0px;
    color: var(--clr-common-white);
  }
  .bd-about-img-five-expe span {
    font-size: 16px;
    font-weight: 500;
    display: block;
    color: var(--clr-common-white);
  }
  
  @media only screen and (min-width: 992px) and (max-width: 1199px), only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .bd-about-text-five.pt-80.mb-30 {
      padding-top: 15px;
      margin-bottom: 15px;
    }
  }
  
  /* @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .bd-about-six-text {
      margin-top: 30px;
    }
  } */
  
  .bd-about-six-feature ul li {
    font-size: 16px;
    line-height: 1;
    color: var(--clr-body-heading);
    margin-bottom: 17px;
    list-style: none;
  }
  @media (max-width: 575px) {
    .bd-about-six-feature ul li {
      display: flex;
    }
  }
  .bd-about-six-feature ul li i {
    font-size: 14px;
    color: var(--clr-theme-1);
    margin-right: 8px;
  }
  
  .bd-about-six-feature-title {
    font-size: 20px;
    font-weight: 600;
    color: var(--clr-body-heading);
  }
  
  .bd-about-six-img {
    position: relative;
    min-height: 100%;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1800px) {
    .bd-about-six-img {
      overflow: hidden;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .bd-about-six-img {
      height: 600px;
    }
  }
  @media (max-width: 1300px) {
    .bd-about-six-img {
      height: auto;
    }
    .bd-about-six-img img.pl-60 {
      display: none;
    }
  }
  .bd-about-six-img .bd-about-six-img-second {
    position: absolute;
    top: 200px;
    left: 0px;
  }
  @media (max-width: 1300px) {
    .bd-about-six-img .bd-about-six-img-second {
      display: none;
    }
  }
  .bd-about-six-img .bd-about-six-img-third {
    position: absolute;
    top: 70px;
    right: 30px;
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px) {
    .bd-about-six-img .bd-about-six-img-third {
      top: 130px;
      right: 0px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .bd-about-six-img .bd-about-six-img-third {
      top: 130px;
      right: -150px;
    }
  }
  @media (max-width: 575px) {
    .bd-about-six-img .bd-about-six-img-third {
      position: static;
    }
  }
  
  .bd-about-six-img-expe {
    position: absolute;
    right: 135px;
    bottom: 70px;
  }
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .bd-about-six-img-expe {
      overflow: hidden;
      right: 88px;
      bottom: 85px;
      background: var(--clr-bg-gray);
    }
  }
  @media only screen and (min-width: 992px) and (max-width: 1199px){
    .bd-about-six-img-expe {
      right: 0px;
      bottom: 0px;
      background: var(--clr-bg-gray);
      padding: 10px;
    }
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px){
    .bd-about-six-img-expe {
      right: -20px;
      bottom: 0px;
      background: var(--clr-bg-gray);
      padding: 10px;
    }
  }
  @media (max-width: 575px) {
    .bd-about-six-img-expe {
      right: 0px;
    }
  }
  .bd-about-six-img-expe h2 {
    font-size: 60px;
    font-weight: 700;
    line-height: 1;
    margin-bottom: 0px;
    color: var(--clr-theme-1);
  }
  .bd-about-six-img-expe span {
    font-size: 18px;
    font-weight: 500;
    color: var(--clr-body-heading);
  }
  
  @media only screen and (min-width: 1200px) and (max-width: 1399px) {
    .bd-about-choose-area.pb-105 {
      padding-bottom: 110px;
    }
  }
  
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .bd-abouts-img-two img {
      width: 100%;
    }
  }
  
  @keyframes hero-dot-1 {
    0% {
      transform: translateY(-60px);
    }
    100% {
      transform: translateY(0px);
    }
  }
  @keyframes hero-dot-2 {
    0% {
      transform: translateY(0px);
    }
    100% {
      transform: translateY(-60px);
    }
  }
  @keyframes hero-circle-1 {
    0% {
      -webkit-transform: rotate(0deg);
      -ms-transform: rotate(0deg);
      transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
      -ms-transform: rotate(360deg);
      transform: rotate(360deg);
    }
  }
  @keyframes hero-bounce {
    0%, 20%, 50%, 80%, 100% {
      transform: translateY(0);
    }
    40% {
      transform: translateY(-30px);
    }
    60% {
      transform: translateY(-15px);
    }
  }
  @keyframes hero-thumb-animation {
    0% {
      -webkit-transform: translateX(80px);
      -moz-transform: translateX(80px);
      -ms-transform: translateX(80px);
      transform: translateX(80px);
    }
    100% {
      -webkit-transform: translateX(0px);
      -moz-transform: translateX(0px);
      -ms-transform: translateX(0px);
      transform: translateX(0px);
    }
  }
  @keyframes hero-thumb-animation2 {
    0% {
      -webkit-transform: translateX(-80px);
      -moz-transform: translateX(-80px);
      -ms-transform: translateX(-80px);
      transform: translateX(-80px);
    }
    100% {
      -webkit-transform: translateX(0px);
      -moz-transform: translateX(0px);
      -ms-transform: translateX(0px);
      transform: translateX(0px);
    }
  }

  @media (min-width:1400px) {
    .first-section-about{
      flex: 0 0 auto;
      width: 50%
    }
  }

  @media only screen and (min-width:350px) and (max-width:991px) {
    .exta-shape{
      display: none;
    }
    .about-main-responsive{
      padding-bottom: 90px;
    }
  }

  @media only screen and (min-width:350px) and (max-width:991px){
    .three-button-respon{
      margin-bottom:10px
    }
  }

  @media only screen and (min-width:350px) and (max-width:700px) {
      .form-img{
        display: none;
      }
  }