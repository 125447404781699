.first_section{
    width:70%
}
.first_section_about{
    width:65%
}
.second_section_about{
    width:35%
}

.second_section{
    width:30%;
    text-align: center;
}

.contact_section{
    font-size: 43px;
    color:white;
    line-height: 55px;
    font-weight: 600;
}
.contact_subtext{
    color:white;
    font-weight:400;
    font-size:22px
}

.contact-stuff{
    display:flex;
    width:100%
}

.contact-stuff_about{
    display:flex;
    width:100%;
    padding-top:5px;
}

.contact_icon{
    width:55%
}

.contact_txt{
    width:100%;
    text-align: left;
}

.contact-sub-text{
    font-size:20px;
    color:white;
    font-weight: 450;
}

.first-para-resp{
    color:white;
    font-weight:400;
    font-size: 25px;
    margin-bottom: 5px;
}



@media only screen and (min-width: 350px) and (max-width: 550px){
    .first_section{
        width:100%
    }
    .second_section{
        width:100%;
        padding-left: 30px;
    }
    
    .icon_respon{
        padding-left: 0px;
        text-align: right;
        padding-right: 15px;
    }
    .contact_txt{
        width:60%
    }
    .first-para-resp{
        font-size:20px;
        text-align: center;
    }
    .second-para-resp{
        font-size:30px;
        line-height:40px;
        text-align: center;
    }
    .contact_subtext{
        font-size:20px;
        text-align: center;
    }
}

@media only screen and (min-width: 551px) and (max-width: 700px){
    .first_section{
        width:100%
    }
    .second_section{
        width:100%;
        padding-left: 80px;
    }
    
    .icon_respon{
        padding-left: 0px;
        text-align: right;
        padding-right: 15px;
    }
    .contact_txt{
        width:60%
    }
    .first-para-resp{
        font-size:24px;
        text-align: center;
    }
    .second-para-resp{
        font-size:38px;
        line-height:44px;
        text-align: center;
    }
    .contact_subtext{
        font-size:23px;
        text-align: center;
    }
}

@media only screen and (min-width: 701px) and (max-width: 800px){
    .first_section{
        width:100%
    }
    .second_section{
        width:100%;
        padding-left: 120px;
    }
    
    .icon_respon{
        padding-left: 0px;
        text-align: right;
        padding-right: 15px;
    }
    .first-para-resp{
        font-size:28px;
        text-align: center;
    }
    .second-para-resp{
        font-size:44px;
        line-height:52px;
        text-align: center;
    }
    .contact_subtext{
        font-size:26px;
        text-align: center;
    }
}

@media only screen and (min-width: 801px) and (max-width: 1050px){
    .first_section{
        width:65%
    }
    .second_section{
        width:35%;
    }
    .first-para-resp{
        font-size:24px;
        
    }
    .second-para-resp{
        font-size:38px;
        line-height:44px;
    }
    .contact_subtext{
        font-size:23px;
     
    }   
    .icon_respon{
        padding-left: 0px;
        text-align: right;
        padding-right: 15px;
    }
    .contact_txt{
width:100%
    }
}
