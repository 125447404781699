/* .ablog__sidebar{
    width: 350px;
} */

.blog-form {
  width: 350px;
}

/* .bd-contact-field input {
    width:350px;
    height:50px;
} */

.dropdown-toggle {
  background-color: var(--clr-bg-gray);
  width: 370px;
  height: 50px;
  text-align: left;
  font-size: 16px;
  opacity: 1;
  color: var(--clr-body-text);
  padding-left: 18px;
}

.blog-size {
  width: 75%;
}
.blog-size2 {
  width: 25%;
}
.drop-menu {
  color: var(--clr-body-text);
  font-weight: 450;
}

.career-text {
  color: var(--clr-body-text);
  font-size: 16px;
}

@media (max-width: 1100px) {
  .bd-blog-deatils-area .container .row {
    flex-direction: column;
  }
}
@media screen and (max-width: 349px) {
  .careerdetail-reponsive-section1 {
    width: 100% !important;
  }
}

@media only screen and (min-width: 320px) and (max-width: 450px) {
  .bd-blog-deatils-area {
    padding-top: 70px;
  }
  .bd-blog-deatils-area .container {
    margin-left: 0px;
  }
}

@media only screen and (min-width: 451px) and (max-width: 576px) {
  .bd-blog-deatils-area {
    padding-top: 160px;
  }

  .bd-blog-deatils-area .container .row {
    width: 600px;
  }
  .bd-blog-deatils-area .container {
    margin-left: 0px;
  }
}

.bd-page-title-bg_careerDetail {
  position: relative;
  background-color: var(--clr-bg-gray);
}

.bd-page-title-bg_careerDetail:before {
  position: absolute;
  content: "";
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background: linear-gradient(
    to bottom,
    rgba(96, 8, 248, 0.2) 0%,
    rgba(96, 8, 248, 0.45) 100%
  );

  /* background: -moz-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: -webkit-linear-gradient(left, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%);
    background: linear-gradient(to right, rgba(239, 4, 84, 0.1) 0%, rgba(96, 8, 248, 0.1) 100%); */
  /* filter: progid:DXImageTransform.Microsoft.gradient( startColorstr="#1aef0454", endColorstr="#1a6008f8",GradientType=1 ); */
}

.detail-main-tittle {
  font-size: 50px;
  color: #4a026c;
}

.careerdetail-reponsive-section1 {
  width: 70%;
}

@media only screen and (min-width: 350px) and (max-width: 991px) {
  .detail-main-tittle {
    font-size: 36px;
  }
  .detail-responsive-tittle {
    padding-top: 150px;
  }
  .careerdetail-reponsive-section1 {
    width: 100%;
  }
  .career-text {
    padding-bottom: 20px;
  }
}

@media only screen and (min-width: 1100px) and (max-width: 1200px) {
  .careerdetail-reponsive-section1 {
    width: 70%;
  }
}
@media only screen and (min-width: 1200px) and (max-width: 1300px) {
  .careerdetail-reponsive-section1 {
    width: 73%;
  }
}
