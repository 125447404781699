/* Testimonial
/*----------------------------------------*/
.bd-testimonial-item .bd-testimonial-icon i {
    font-size: 50px;
    line-height: 1;
    display: inline-block;
    color: var(--clr-theme-1);
  }
  .bd-testimonial-item p {
    font-size: 20px;
    line-height: 1.6;
  }

  .slick-slider {
    overflow: hidden;
    cursor: pointer;
  }
  
  @media (max-width: 767px) {
    .bd-testimonial-item p {
      font-size: 18px;
    }
  }
  .bd-testimonial-item p.mb-25 {
    margin-bottom: 28px;
  }
  .bd-testimonial-item .bd-testimonial-title {
    font-size: 36px;
    line-height: 1.12;
    font-weight: 600;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), (max-width: 767px) {
    .bd-testimonial-item .bd-testimonial-title {
      font-size: 28px;
    }
  }
  .bd-testimonial-item span {
    font-size: 16px;
    display: block;
  }
  
  .testimonial-nav {
    width: 320px;
    margin: 0 auto;
  }
  @media (max-width: 575px) {
    .testimonial-nav {
      width: 300px;
    }
  }
  .testimonial-nav .swiper-slide {
    width: 90px;
    text-align: center;
  }
  .testimonial-nav .swiper-slide .bd-testimonial-img {
    height: 80px;
    width: 80px;
    object-fit: cover;
    border-radius: 50%;
    border: 6px solid var(--clr-common-white);
    display: inline-block;
  }
  .testimonial-nav .swiper-slide .bd-testimonial-img img {
    cursor: pointer;
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  .testimonial-nav .swiper-slide.swiper-slide-prev + .swiper-slide-active {
    margin-right: 28px !important;
    margin-left: -3px !important;
  }
  .testimonial-nav .swiper-slide.swiper-slide-prev + .swiper-slide-active .bd-testimonial-img {
    height: 105px;
    width: 105px;
    border: 8px solid var(--clr-common-white);
  }
  .testimonial-nav .swiper-slide.swiper-slide-prev .bd-testimonial-img, .testimonial-nav .swiper-slide.swiper-slide-next .bd-testimonial-img {
    margin-top: 13px;
  }
  
  .bd-testimonial-four {
    display: flex;
  }
  @media (max-width: 575px) {
    .bd-testimonial-four {
      display: inherit;
    }
  }
  .bd-testimonial-four-img {
    height: 220px;
    width: 220px;
    border-radius: 50%;
    padding: 50px;
    border: 2px solid var(--clr-border-4);
    margin-right: 80px;
    min-width: 220px;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px), only screen and (min-width: 576px) and (max-width: 767px), (max-width: 575px) {
    .bd-testimonial-four-img {
      height: 150px;
      width: 150px;
      padding: 15px;
      margin-right: 40px;
      min-width: 150px;
    }
  }
  @media (max-width: 575px) {
    .bd-testimonial-four-img {
      margin-right: 0px;
      margin-bottom: 30px;
    }
  }
  .bd-testimonial-four-img img {
    height: 100%;
    width: 100%;
    object-fit: cover;
    border-radius: 50%;
  }
  .bd-testimonial-four-text i {
    font-size: 30px;
    color: var(--clr-theme-1);
    display: inline-block;
    line-height: 1;
  }
  .bd-testimonial-four-text h6 {
    font-size: 16px;
    font-weight: 500;
    color: var(--clr-body-text);
    position: relative;
    line-height: 1;
    display: inline-block;
  }
  .bd-testimonial-four-text h6:before {
    position: absolute;
    content: "";
    height: 1px;
    background: var(--clr-body-text);
    width: 80px;
    top: 8px;
    right: -86px;
  }
  
  .bd-testimonial-four-text-title {
    font-size: 36px;
    line-height: 1.33;
    font-weight: 600;
    font-style: italic;
  }
  @media only screen and (min-width: 768px) and (max-width: 991px) {
    .bd-testimonial-four-text-title {
      font-size: 28px;
    }
  }
  @media (max-width: 767px) {
    .bd-testimonial-four-text-title {
      font-size: 24px;
    }
  }
  @media (max-width: 575px) {
    .bd-testimonial-four-text-title {
      font-size: 20px;
    }
  }

  .side_video{
    height:420px;
    width:600px;
    border-radius: 10px;
  }

  .media_testimonial{
    width:51%;
  }

  .vid_fluid{
    width: 600px
  }
  .second_width_test{
    width: 49%
  }

  @media only screen and (min-width:350px) and (max-width: 1000px) {
    .media_test{
      flex-direction: column;
    }
    .media_testimonial{
      width: 100%;
    }
    .vid_fluid{
      width:100%;
    }
    .second_width_test{
      width: 100%;
      height:190px
    }
    .side_video{
      width:100%
    }
  }

  .testimonial-name{
    color:var(--clr-body-heading);
    font-weight: 550;
  
  }

  @media only screen and (min-width:350px) and (max-width: 991px) {
   .bd-testimonial-area{
    padding-top:10px
   }
  }

  .slick-slide > div {
    display: grid;
    place-items: center;
    }
  
.bd-service-test{
  border-radius: 5px;
background: var(--clr-common-white);
transition: all 0.3s ease-out 0s;
padding: 13px 38px 14px 38px;
}

.home-testimonial-img{
  display:flex;
}
.first-test1{
  width:40%
}
.first-test2{
  width:50%;
  margin-right:50px;
  text-align: left;
}
.second-test1{
  width:33%;
}
.third-test1{
  width:35%;
}
.third-test2{
  width:60%;
  margin-right: 50px;
  text-align:left;
}
.fourth-test1{
  width:33%;

  text-align:left;
}
.sixth-test1{
  width:35%
}
.sixth-test2{
  width:80%;
  margin-right:20px;
  text-align:left
}
.last-test1{
  width:35%;
}
.last-test2{
  width:50%;
  margin-right:50px;
  text-align:left;
}

@media only screen and (min-width:350px) and (max-width:991px){
  .testimonial_video{
    padding-left:0px;
    padding-right:8px
  }
  .home-testimonial-img{
    flex-direction: column;
  }
  .first-test1{
    width:100%;
    margin-bottom:20px
  }
  .first-test2{
    width:100%;
    text-align: center;
  }
  .second-test1{
    width:100%;
    margin-bottom:20px
  }
  .third-test1{
    width:100%;
    margin-bottom:20px;
    margin-left:50px;
  }
  .third-test2{
    width:100%;
    text-align:center
  }
  .fourth-test1{
    width:100%;
    margin-bottom:20px;
    margin-left:50px;
  }
  .sixth-test1{
    width:100%;
    margin-bottom:20px;
    margin-left:50px
  }
  .sixth-test2{
    width:100%;
    text-align:center
  }
  .last-test1{
    width:100%;
    margin-bottom:20px;
    margin-left:30px;

  }
  .last-test2{
    width:100%;
    text-align:center;

  }
}

